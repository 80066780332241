import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FileModel, FileReadOnlyModel, Result } from '../models/models';
import { ResultHelper } from '../common/result-extension';
import { ErrorHandlingService } from './error-handling.service';
import { customFilterModel } from '../../../../goldstar-internal/src/app/models/models';
import { EntityGetRequest } from '../api-data/ng-openapi-gen-next/models';

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	constructor(private errorHandling: ErrorHandlingService) {}

	public bindModelToForm(model: any, form: UntypedFormGroup) {
		const keys = Object.keys(form.controls);

		keys.forEach((key) => {
			model[key] = form.value[key];
			// form.controls[key].valueChanges.subscribe(
			//     (newValue) => {
			//         model[key] = newValue;
			//     }
			// )
		});
		return model;
	}

	async toResultData<T>(func: Function): Promise<Result<T>> {
		try {
			return await func();
		} catch (error: any) {
			console.log('Error logged -----', error);
			return ResultHelper.failedResponse(error);
		}
	}

	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}

	public hasDuplicates(sourceCollection: File[], targetCollection: File[]): boolean {
		let allowUpload: boolean = true;
		sourceCollection.forEach((file: File) => {
			if (targetCollection.find((x) => x.name == file.name)) {
				this.errorHandling.showErrorMessage('Attachment with same name is not allowed.');
				allowUpload = false;
				sourceCollection = [];
			}
		});
		return allowUpload;
	}

	public hasDuplicateFileModel(sourceCollection: File[], targetCollection: FileModel[]): boolean {
		let allowUpload: boolean = true;
		sourceCollection.forEach((file: File) => {
			if (targetCollection.find((x) => x.file.name == file.name)) {
				this.errorHandling.showErrorMessage('Attachment with same name is not allowed.');
				allowUpload = false;
				sourceCollection = [];
			}
		});
		return allowUpload;
	}

	public hasDuplicateFileReadOnlyModel(sourceCollection: File[], targetCollection: FileReadOnlyModel[]): boolean {
		let allowUpload: boolean = true;
		sourceCollection.forEach((file: File) => {
			if (targetCollection.find((x) => x.fileName == file.name)) {
				this.errorHandling.showErrorMessage('Attachment with same name is not allowed.');
				allowUpload = false;
				sourceCollection = [];
			}
		});
		return allowUpload;
	}

	replaceCustomColumn(request: EntityGetRequest, customColumn: customFilterModel[]): EntityGetRequest {
		if (request.searchFilter) {
			const updatedSearchFilter = request.searchFilter.map((filter) => {
				if ('searchColumn' in filter) {
					const customColumnToUpdate = customColumn.find((cc) => cc.aliasName === filter.searchColumn);
					if (customColumnToUpdate) {
						return { ...filter, searchColumn: customColumnToUpdate.columnName, searchTable: customColumnToUpdate.tableName };
					}
				}
				return filter;
			});
			request = { ...request, searchFilter: updatedSearchFilter };
		}

		if (request.sortColumns) {
			const updatedSortColumns = request.sortColumns.map((sortItem) => {
				const customColumnToUpdate = customColumn.find((cc) => cc.aliasName === sortItem.columnName);
				if (customColumnToUpdate) {
					return { ...sortItem, columnName: customColumnToUpdate.columnName, tableName: customColumnToUpdate.tableName, aliasName: customColumnToUpdate.aliasName };
				}
				return sortItem;
			});
			request = { ...request, sortColumns: updatedSortColumns };
		}

		return request;
	}
}
