import {
	DashboardLinkedMenu,
	InternalPermissionListItem,
	InternalRoleListItem,
	InternalTitleListItem,
	InternalUserItem,
	MenuList,
	ReferralComments,
} from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { MenuItemModel } from '../../models/models';

export enum ReportingAdminDashboardTabs {
	Dashboard = 'Dashboard',
	DashboardPages = 'DashboardPages',
}

export interface MenuGroupUIModel {
	name: string;
	iconName: string;
	selected: boolean;
	items: MenuUIModel[];
}

export interface MenuUIModel {
	urlGUID: string | undefined;
	name: string;
	selected: boolean;
	nestedChild: boolean;
	menuItem: MenuItemModel;
	items: MenuUIModel[];
	childVisible: boolean;
	isExternalLink: boolean;
	externalLinkURL?: string;
}

export interface DropDownMenuModel {
	name: string;
	icon: string;
	onClick: Function;
}

export interface RoutePathConfig {
	rooted: boolean;
	component?: string;
}

export interface MenuGroupIcon {
	ID: string;
	iconName: string;
	iconPrev: string;
}

export interface FavoriteMenuModel {
	id: string;
	name: string;
	backgroundColor: string;
	iconName: string;
	isEditable: boolean;
	favoriteOrder: number;
	description: string;
	isExternal: boolean;
	externalLink: string;
	menuItemURL: string;
	menuItem: MenuItemModel;
	isWikiFavorite?: boolean | null;
	queryParams?: {} | null;
}

export interface InheritedRolesDialogData {
	dataSource: MenuList[];
}

export interface ReportingDashboardMenuDialogData {
	dataSource: DashboardLinkedMenu[];
}

export interface RoleXPermissionDialogData {
	dataSource: InternalPermissionListItem[];
}

export interface UserRoleXDialogData {
	dataSource: InternalUserItem[];
	showSaveButton?: boolean;
}

export interface RoleForUserDataSource {
	dataSource: InternalRoleListItem[];
	userItem: InternalUserItem;
}

export interface RoleXTitleDialogData {
	dataSource: InternalTitleListItem[];
}

export interface UserGroupXUserDialogData {
	dataSource: InternalUserItem[];
}

export interface NotificationMode {
	name: string;
	label: string;
}
export interface ReferralCommentByDate {
	commentDate?: string;
	referralComments?: ReferralComments[];
}
// IT IS USED TO IMPLEMENTED THE COMPONENT NAME AT THE RUN TIME, ALL STANDALONE COMPONENTS SHOULD USE IT
export interface INamedComponent {
	resolveComponentName(): string | undefined;
}

export enum SpinnerState {
	show = 'Show',
	hide = 'Hide',
}

export interface DropdownModel {
	text: string;
	selectedOptions: any[];
	unSelectedOptions: any[];
	dropdownFieldName: string;
	id: string;
}

export enum NotificationMessageType {
	Chat = 'Chat',
	Notification = 'Notification',
}
export interface ChatItem {
	entityGUID: string;
	internalUserGUID: string;
	internalUserName: string;
	chatMessage: string;
	chatDateTime: Date;
}
export interface ChatsDataByDate {
	chatDate?: string;
	chats?: ChatEntityModel[];
}

export interface ChatEntityModel {
	comment?: string | null;
	commentDate?: string | null;
	commentGUID?: string | null;
	entityGUID?: string | null;
	entityXCommentGUID?: string | null;
	userGUID?: string | null;
	userName?: string | null;
}

export interface EmployeeSchedule {
	slotOneStartTime?: any;
	weekDayLabel?: string;
	weekDaySystemCode?: string;
	gsScheduleDOWGUID?: string;
	slotOneEndTime?: any;
	slotTwoStartTime?: any;
	slotTwoEndTime?: any;
	startTime?: any;
	endTime?: any;
	slotOneChecked?: boolean | null | undefined;
	slotTwoChecked?: boolean | null | undefined;
	notAvailable?: boolean | null | undefined;
	timeSlotOneError?: boolean | null | undefined;
	timeSlotTwoError?: boolean | null | undefined;
	timeConflictError?: boolean | null | undefined;
	timeList?: any[];
	type?: string;
	slot1CustomerAppointmentAvailabilityGUID?: string;
	slot2CustomerAppointmentAvailabilityGUID?: string;
	internalUserCustomerAppointmentAvailabilityGUID?: string;
}

export enum SlotType {
	SLOT_1 = 'slot1',
	SLOT_2 = 'slot2',
}

export enum DOWSystemCode {
	MONDAY = 'MONDAY',
}

export enum DOWLabel {
	MONDAY = 'monday',
}

export interface FavoriteMenuGroupModel {
	id: string;
	label: string;
	isActive: boolean;
	items: FavoriteMenuModel[];
}
export enum FavoriteMenuGroupItemId {
	EXTERNAL_LINK = '1',
	RESOURCE_CENTER = '2',
	GOLDSTAR = '3',
	RECENTLY_VISITED = '4',
}
export enum FavoriteMenuGroupItemLabel {
	EXTERNAL_LINK = 'External Links',
	RESOURCE_CENTER = 'Resource Center',
	GOLDSTAR = 'Goldstar',
	RECENTLY_VISITED = 'Recently Visited',
}
