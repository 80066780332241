<section class="complete-event-modal" *ngIf="showToDoActionModal">
	<div class="modal fade show" style="display: block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body">
					<div class="container">
						<div class="row modal-headerr">
							<div class="col-10 col-md-10 col-lg-10">
								<label class="modal-head-label">{{ modalAction | titlecase }} Event</label>
							</div>
							<div class="col-2 col-md-2 col-lg-2 align-close-icon">
								<button type="button" (click)="closeActionModal()" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
						</div>
						<div class="complete-event-main">
							<div *ngIf="modalAction != 'snooze'">
								<div class="event-text">
									<p>
										Are you sure you want to {{ modalAction }} <span> {{ selectedToDoItem.eventName }}?</span>
									</p>
								</div>
								<form class="referral-form" #textField="ngForm">
									<div [className]="textField.controls.comments && textField.controls.comments.hasError('required') && textField.controls.comments.touched ? 'validation-error' : ''">
										<div class="position-rel">
											<label style="margin-bottom: 2px">{{ modalAction == 'complete' ? 'Completion' : 'Cancellation' }} Details</label>
											<dx-text-area placeholder="Enter comments" type="text" name="comments" id="comments" required [height]="70" [maxLength]="256" [(ngModel)]="comments">
											</dx-text-area>
											<i class="fa fa-exclamation-circle error-symbol error-symbol-textarea" aria-hidden="true"></i>
											<span class="error-text">Comment is required.</span>
										</div>
									</div>
								</form>
							</div>
							<div *ngIf="modalAction == 'snooze'" class="snooze-event-main">
								<div class="event-text">
									<form class="referral-form" #textField="ngForm">
										<div
											[ngClass]="{
												'validation-error':
													textField.controls.snoozeDays &&
													(textField.controls.snoozeDays.dirty || textField.controls.snoozeDays.touched) &&
													textField.controls.snoozeDays.hasError('required'),
											}"
										>
											<span class="snooze-span">Snooze by</span>
											<span class="position-rel">
												<dx-number-box
													[stylingMode]="stylingMode"
													[labelMode]="labelMode"
													placeholder="days"
													name="snoozeDays"
													id="snoozeDays"
													type="number"
													required
													[(ngModel)]="snoozeDays"
													[width]="100"
													[min]="1"
													[showSpinButtons]="true"
													(ngModelChange)="onNgModelChange(textField)"
												>
												</dx-number-box>
												<i class="fa fa-exclamation-circle error-symbol" aria-hidden="true"></i>
											</span>

											<span class="snooze-span">days.</span>
											<span class="max-snooze"
												>(Max Snooze: <span class="max-snooze-date">{{ selectedToDoItem.maxSnoozeDate | date: 'MM-dd-yyyy' }})</span></span
											>
											<div>
												<span class="error-text" *ngIf="textField.controls.snoozeDays?.hasError('required')"> Snooze days is required. </span>
											</div>
										</div>

										<div *ngIf="!textField.controls.snoozeDays?.hasError('max') && !textField.controls.snoozeDays?.hasError('required')">
											<div class="snoozed-date" *ngIf="snoozeDays">
												<span
													>Snoozed until <span>{{ newSnoozeDate | date: 'MM-dd-yyyy' }}</span></span
												>
											</div>
										</div>
									</form>
								</div>
								<div *ngIf="limitExceeded" class="errorMessageStyle">
									<!-- <mat-error> Max Snooze Limit Reached. </mat-error> -->
								</div>
							</div>

							<div class="cancel-submit-btn">
								<button type="button" class="btn btn-primary cancel-btn" (click)="closeActionModal()">Cancel</button>
								<button
									[disabled]="disableEventButton"
									type="button"
									(click)="modalAction == 'snooze' ? saveToDoSnoozeHistoryData() : saveToDoListEventActionData(modalAction)"
									class="btn btn-primary submit-btn"
								>
									{{ modalAction | titlecase }} Event
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
