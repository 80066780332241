import { Injectable } from '@angular/core';
import { WikiManualSidebar } from '../interface/wiki-manual-sidebar';
import { Subject, lastValueFrom } from 'rxjs';
import { BookPageBreadcrumb } from '../interface/BookPageBreadcrumb';
import { ApiService } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services/api.service';
import { v4 as uuidv4 } from 'uuid';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../goldstar-internal/src/app/models/classes';
import { FavoriteMenuUpdateAction, WikiModuleStateAction } from '../../../../../store/store.actions';
import { MenuService } from '../../../../../services/menuService';
import { Breadcrumb } from '../../../../../utilities/internal-share/models/model';
import {
	EntityGetRequest,
	FetchInternalWikiModuleRequest,
	InternalPermissionListItem,
	InternalPermissionTypeItem,
	InternalWikiModuleXs3Video,
	InternalWikiUpdateResponseModel,
	S3PresignedUrlResponse,
	UpdateInternalWikiModuleRequest,
	UserPeerRequest,
	WikiManualBookResponse,
	WikiManualKeywordResponse,
	WikiPermissionGetRequest,
	WikiPermissionRequest,
	WikiRoleXPermissionItem,
} from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { MenuGroupModel, MenuItemModel, Result } from '../../../../../models/models';
import { AuthService } from '../../../../../components/auth/auth.service';
import { UserInfoService } from '../../../../../services/user-info.service';
import { WikiImport } from '../interface/wiki-import';
import _ from 'lodash';
import { LoaderService } from '../../../loader/service/loader.service';
import { ResultHelper } from '../../../../../../../../goldstar-share/src/app/common/result-extension';
import { PermissionService } from '../../../../../../../../goldstar-share/src/app/services/permission.service';
import { WikiPermissionModel, WikiRoleAccess, WikiStaticFormatType, WikiStaticUserRolesValues } from '../wiki-manual.model';

@Injectable({
	providedIn: 'root',
})
export class WikiManualService {
	bookData: WikiManualBookResponse[] = [];
	sidebarTreeList: WikiManualSidebar[] = [];
	public selectedSideBarItem?: WikiManualSidebar | undefined = undefined;
	onSidebarItemSelected = new Subject<WikiManualSidebar>();
	onSearchKeywordChange = new Subject<WikiManualKeywordResponse>();
	bookBreadcrumbList: BookPageBreadcrumb[] = [];
	public methodInitialized: boolean = false;
	importModelData = new Subject<WikiImport>();
	scrollHeight: number = 0;
	initialLoad: boolean = true;
	public loggedInUserId!: string;
	public isApiCallInProgress = false;
	public isCustomQuillComponentsRegistered = false;
	public isVideoApiCallInProgress = false;
	permissionTypeList: InternalPermissionTypeItem[] = [];

	constructor(
		private apiV2: ApiService,
		private store: Store<AppState>,
		public menuService: MenuService,
		private authService: AuthService,
		private userInfoService: UserInfoService,
		public loaderService: LoaderService,
		private permissionService: PermissionService
	) {}
	getBookData() {
		return this.bookData.slice();
	}

	async getWikiModuleData() {
		try {
			let userGuidModel: UserPeerRequest = {
				userId: this.authService.userId,
			};
			let response = await lastValueFrom(this.apiV2.getWikiModuleData({ body: userGuidModel }));
			if (response.isSuccess && response.data) {
				if (response.data.items) {
					this.sidebarTreeList = [];
					this.bookData = response.data.items;
					const organizedData = this.organizeData(this.bookData, new Map<string, WikiManualSidebar>());
					this.sidebarTreeList = organizedData.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
					this.selectedSideBarItem = this.sidebarTreeList.length > 0 ? this.sidebarTreeList[0] : undefined;
					this.bookBreadcrumbList = this.selectedSideBarItem
						? [
								{
									id: this.selectedSideBarItem?.id ?? '',
									title: this.selectedSideBarItem?.text ?? '',
									favoriteYN: this.selectedSideBarItem?.favoriteYN ?? 'N',
									favoriteOrder: this.selectedSideBarItem?.favoriteOrder ?? 0,
									queryParams: { moduleGUID: `${this.selectedSideBarItem?.id}` },
								},
							]
						: [];
					this.store.dispatch(
						WikiModuleStateAction({
							isWikiModuleDataUpdated: true,
						})
					);
					return this.bookData;
				}
			}
			return null;
		} catch (error) {
			console.error('Error fetching data:', error);
			return null;
		}
	}

	async checkIfUserHasDevPermission() {
		const hasDevRole = await this.permissionService.userHasDevPermission(this.loggedInUserId);
		return hasDevRole;
	}

	checkModuleAccessPermission(selectedItem: WikiManualSidebar, roleAccess: WikiRoleAccess) {
		let hasPermission: boolean = false;
		if (this.bookData.length > 0 && selectedItem) {
			let role = selectedItem?.userRole.toLowerCase();
			if (role != '') {
				switch (roleAccess) {
					case WikiRoleAccess.EDIT:
						return (hasPermission = role !== WikiStaticUserRolesValues.USER.toLowerCase() ?? false);
					case WikiRoleAccess.ASSIGN:
						return (hasPermission = role == WikiStaticUserRolesValues.MANAGER.toLowerCase() ?? false);
					default:
						return false;
				}
			}
		}
		return hasPermission;
	}

	recursiveModuleIdSearch(data: WikiManualSidebar[], searchId: string) {
		for (const item of data) {
			if (item.id == searchId) {
				return true;
			} else {
				//Found the parent node , now check its children for search id

				if (item.items && item.items.length > 0) {
					if (this.recursiveModuleIdSearch(item.items, searchId)) {
						return true;
					}
				}
			}
		}
		return false;
	}
	isModuleIdPresentInBook(bookId: string, searchId: string) {
		const bookData = this.sidebarTreeList.filter((x) => x.id == bookId);
		return this.recursiveModuleIdSearch(bookData, searchId);
	}

	organizeData(inputData: WikiManualBookResponse[], existingBooksMapData: Map<string, WikiManualSidebar>): WikiManualSidebar[] {
		const booksMap = existingBooksMapData ?? new Map<string, WikiManualSidebar>();
		let itemsNotAdded: WikiManualBookResponse[] = [];
		for (const item of inputData) {
			const module = {
				id: item.internalWikiModuleGUID,
				text: item.label || '',
				index: item.orderIndex || 0,
				items: [],
				parentId: item.parentInternalWikiModuleGUID || '',
				content: item.content || '',
				isActive: false,
				expanded: false,
				moduleLevel: item.depth,
				deletedYN: item.deletedYN ?? '',
				isNewModule: false,
				favoriteYN: item.favoriteYN ?? 'N',
				moduleHasContent: item.moduleHasContent ?? false,
				favoriteOrder: item.favoriteOrder ?? 0,
				isModuleUpdated: false,
				userRole: item.userRole ?? '',
				systemCode: item.systemCode ?? null,
				bookName: item.bookName ?? '',
			};

			if (item.parentInternalWikiModuleGUID) {
				const parentModule = booksMap.get(item.parentInternalWikiModuleGUID);
				if (parentModule) {
					parentModule.items = parentModule.items || [];
					parentModule.items.push({
						...module,
					});
				} else {
					const subModuleParent = this.findModule(Array.from(booksMap.values()), item.parentInternalWikiModuleGUID);
					if (subModuleParent) {
						subModuleParent.items = subModuleParent.items || [];
						subModuleParent.items.push({
							...module,
						});
					} else {
						let index = inputData.findIndex((x) => x.internalWikiModuleGUID == item.parentInternalWikiModuleGUID);
						if (index > -1) {
							itemsNotAdded.push(item);
						}
					}
				}
			}
			if (!booksMap.has(item.internalWikiModuleGUID ?? '') && !item.parentInternalWikiModuleGUID) {
				booksMap.set(item.internalWikiModuleGUID ?? '', module);
			}
		}
		let booksData = Array.from(booksMap.values());

		if (itemsNotAdded.length > 0) {
		} else {
			if (booksData) {
				booksData = this.sortItems(booksData);
			}
		}
		return booksData;
	}

	sortItems(booksData: WikiManualSidebar[]) {
		for (const module of booksData) {
			if (module.items.length > 0) {
				module.items = module.items.sort((a, b) => (a.index || 0) - (b.index || 0));
				if (module.items) {
					this.sortItems(module.items);
				}
			}
		}
		return booksData;
	}

	async getInternalWikiModuleContent(internalWikiModuleGUID: string) {
		try {
			if (!this.isApiCallInProgress) {
				this.isApiCallInProgress = true;
				const loggedInUser = await this.userInfoService.getCurrentLoggedInUser();

				let wikiModuleGuidModel: FetchInternalWikiModuleRequest = {
					internalWikiModuleGUID: internalWikiModuleGUID,
					internalUserGUID: loggedInUser.internalUserGUID,
				};
				let response = await lastValueFrom(this.apiV2.getInternalWikiModuleContent({ body: wikiModuleGuidModel }));
				this.isApiCallInProgress = false;
				if (!response.isSuccess || !response.data) {
					return null;
				} else {
					let selectedItem = response.data;
					const index = this.bookData.findIndex((x) => x.internalWikiModuleGUID === selectedItem.internalWikiModuleGUID);
					if (index > -1) {
						this.bookData[index].content = selectedItem.content;
						this.updateModuleContent(this.sidebarTreeList, selectedItem.internalWikiModuleGUID ?? '', selectedItem.content ?? '');
					}
					return selectedItem;
				}
			}
			return this.selectedSideBarItem;
		} catch (error) {
			console.error('Error fetching data:', error);
			return null;
		}
	}

	// Function to find a module by ModuleGUID
	findModule(modules: WikiManualSidebar[], parentModuleGUID: string): WikiManualSidebar | undefined {
		for (const module of modules) {
			if (module.id === parentModuleGUID) {
				return module;
			}
			if (module.items && module.items.length > 0) {
				const foundModule = this.findModule(module.items, parentModuleGUID);
				if (foundModule) {
					return foundModule;
				}
			}
		}
		return undefined;
	}
	getWikiSidebarList() {
		if (this.sidebarTreeList.length == 0) {
			const organizedData = this.organizeData(this.bookData, new Map<string, WikiManualSidebar>());
			this.sidebarTreeList = organizedData.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
		}
		return this.sidebarTreeList.slice();
	}

	updateSelectedSideBarItem(data: WikiManualSidebar[], itemId: string, action: boolean) {
		for (const item of data) {
			if (item.isNewModule) item.isNewModule = false;

			if (item.id === itemId) {
				item.isActive = action;
				item.expanded = action || item.items.length > 0;
				this.selectedSideBarItem = item;

				if (action) {
					let parent: WikiManualSidebar | null = item;
					while (parent && parent.parentId) {
						const parentId: string = parent.parentId;
						parent = data.find((x) => x.id === parentId)!;
						if (parent) {
							parent.expanded = true;
						}
					}
				}
			} else {
				item.isActive = false;
			}

			if (item.items && item.items.length > 0) {
				this.updateSelectedSideBarItem(item.items, itemId, action);
			}
		}

		this.sidebarTreeList = data;
		return;
	}

	updateSelectedModuleIndex(data: WikiManualSidebar[], itemId: string, newData: WikiManualSidebar) {
		for (const item of data) {
			if (item.id === itemId) {
				item.index = newData.index;
				item.parentId = newData.parentId;
				item.moduleLevel = newData.moduleLevel;

				break;
			}
			if (item.items && item.items.length > 0) {
				this.updateSelectedModuleIndex(item.items, itemId, newData);
			}
		}
		return data;
	}

	/* <summary>
  date: 15-09-2023
  Name: GM
  description: Added method to update the selected book item
  ]
  <summary>*/
	updateBookSelection(selectedItemId: string): WikiManualSidebar | undefined {
		if (selectedItemId != '') {
			this.updateSelectedSideBarItem(this.sidebarTreeList, selectedItemId, true);
			let empty: WikiManualSidebar = {
				items: [],
				index: 0,
				isNewModule: true,
				favoriteYN: 'N',
				isModuleUpdated: true,
				userRole: '',
			};
			this.bookBreadcrumbList = this.findParentItems(this.sidebarTreeList, selectedItemId);

			this.onSidebarItemSelected.next(this.selectedSideBarItem || empty);
			return this.selectedSideBarItem;
		}
		return undefined;
	}

	findParentItems(data: WikiManualSidebar[], id: string, parentItems: BookPageBreadcrumb[] = []): any {
		for (const item of data) {
			if (item.id === id) {
				// Found the target item, add its parents to the result
				return [...parentItems, { id: item.id, title: item.text, favoriteYN: item.favoriteYN ?? 'N', favoriteOrder: item.favoriteOrder ?? 0, queryParams: { moduleGUID: `${item?.id}` } }];
			}

			if (item.items && item.items.length > 0) {
				const result = this.findParentItems(item.items, id, [
					...parentItems,
					{ id: item.id || '', title: item.text || '', favoriteYN: item.favoriteYN ?? 'N', favoriteOrder: item.favoriteOrder ?? 0, queryParams: { moduleGUID: `${item?.id}` } },
				]);
				if (result.length > 0) {
					return result; // If found in the child items, return immediately
				}
			}
		}

		return [];
	}

	getDefaultBookItem() {
		if (this.sidebarTreeList.length > 0) {
			const defaultItem = this.sidebarTreeList.slice(0, 1);
			if (defaultItem.length > 0) {
				let moduleSelected = defaultItem[0];
				if (moduleSelected.parentId == '' && moduleSelected.items && moduleSelected.items.length > 0) {
					moduleSelected = moduleSelected.items[0];
				}
				this.selectedSideBarItem = moduleSelected;
			}
		}
		let empty: WikiManualSidebar = {
			items: [],
			index: 0,
			isNewModule: true,
			favoriteYN: 'N',
			isModuleUpdated: true,
			userRole: '',
		};

		return this.selectedSideBarItem ?? empty;
	}

	getBookBreadcrumbList() {
		return this.bookBreadcrumbList.slice();
	}
	searchKeywordChange(keywordList: WikiManualKeywordResponse) {
		this.onSearchKeywordChange.next(keywordList);
	}

	updateBookBreadCrumbList(selectedItemId: string, isRolesView?: boolean, componentName?: string[], queryParams?: {} | null) {
		this.bookBreadcrumbList = selectedItemId != '' ? this.findParentItems(this.sidebarTreeList, selectedItemId) : [];

		if (isRolesView && componentName) {
			componentName.map((x) => {
				const rolesBreadcrumb: BookPageBreadcrumb = {
					id: this.newGUID(),
					title: x,
					favoriteOrder: 0,
					queryParams: queryParams ?? null,
				};

				this.bookBreadcrumbList.push(rolesBreadcrumb);
			});
		}
	}

	/* <summary>
  date: 16-10-2023
  Name: HV
  description: method for updating existing book or add new books, modules or sub-modules in InternalWikiModule table
  <summary>*/

	async addNewOrUpdateWikiModuleData(data: UpdateInternalWikiModuleRequest, sideBarUpdatedList: WikiManualSidebar[], dataImported: boolean) {
		try {
			let response = await lastValueFrom(this.apiV2.addNewOrUpdateWikiModuleData({ body: data }));
			if (response.isSuccess && response.data && response.data.items) {
				let wikiModuleData: WikiManualBookResponse[] = response.data.items;

				wikiModuleData.forEach((item) => {
					item.depth = item.moduleLevel ?? item.depth;
					item.isNewModule = false;
					item.userRole = item.userRole;
					const index = this.bookData.findIndex((x) => x.internalWikiModuleGUID == item.internalWikiModuleGUID);
					if (index > -1) {
						this.bookData[index] = item;
					} else {
						this.bookData.push(item);
					}
				});

				if (!dataImported) {
					this.sidebarTreeList = sideBarUpdatedList.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
				} else {
					const organizedData = this.organizeData(this.bookData, new Map<string, WikiManualSidebar>());
					this.sidebarTreeList = organizedData.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
				}
				return true;
			}

			return false;
		} catch (e) {
			console.log(e, 'Error');
			return false;
		}
	}

	async deleteWikiModuleData(bookItemsToDelete: WikiManualBookResponse[], sideBarUpdatedList: WikiManualSidebar[], roleXPermissionList: WikiRoleXPermissionItem[]) {
		try {
			let data: UpdateInternalWikiModuleRequest = {
				wikiModuleList: bookItemsToDelete,
				wikiPermissionList: {
					wikiRoleXPermissionList: roleXPermissionList,
				},
			};
			let response = await lastValueFrom(this.apiV2.deleteWikiModuleData({ body: data }));

			if (response.isSuccess) {
				bookItemsToDelete.forEach(async (item) => {
					if (response.isSuccess) {
						const index = this.bookData.findIndex((x) => x.internalWikiModuleGUID == item.internalWikiModuleGUID);
						if (index > -1) {
							this.bookData = this.bookData.filter((x) => x.internalWikiModuleGUID != item.internalWikiModuleGUID);
						}

						await this.removeFavoriteItems(item);
					}
				});
				this.sidebarTreeList = sideBarUpdatedList.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
				return true;
			}
			return false;
		} catch (e) {
			console.log(e, 'Error');
			return false;
		}
	}

	async removeFavoriteItems(item: WikiManualBookResponse) {
		this.menuService.menuGroupCollection.forEach((menuGroup: MenuGroupModel) => {
			menuGroup.menuItems.forEach((menuItemModel: MenuItemModel) => {
				if (menuItemModel.menuGUID === item.internalWikiModuleGUID) {
					menuItemModel.isFavorite = false;
					menuItemModel.favoriteOrder = 0;
				}
			});
		});
		// Refresh the favorite menus
		const clonedFavoriteMenus = _.cloneDeep(this.menuService.favoriteMenuCollection);
		this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));
		await this.updateWiKiFavoriteModule(item.internalWikiModuleGUID ?? '', false, 0);
	}

	getBookOrderIndex() {
		return this.bookData.length;
	}

	newGUID(): string {
		return uuidv4();
	}

	/* <summary>
  date: 13-10-2023
  Name: HV
  description:method for updating editor content in InternalWikiModule table
  <summary>*/

	async updateWikiModuleEditorData(newBooks: WikiManualSidebar[], updatedBookItem: WikiManualSidebar, keywordList: WikiManualKeywordResponse[], s3VideoList: InternalWikiModuleXs3Video[]) {
		try {
			this.sidebarTreeList = newBooks;
			const index = this.bookData.findIndex((x) => x.internalWikiModuleGUID == updatedBookItem.id);
			if (index > -1) {
				this.bookData[index].content = updatedBookItem.content ?? '';
				this.bookData[index].moduleHasContent = updatedBookItem.moduleHasContent ?? false;
				this.bookData[index].systemCode = updatedBookItem.systemCode ?? null;

				let wikiModuleData: InternalWikiUpdateResponseModel = {
					updatedWikiModuleData: this.bookData[index],
					keywordList: keywordList,
					s3VideoList: s3VideoList,
				};

				let response = await lastValueFrom(this.apiV2.updateWikiModuleEditorContentData({ body: wikiModuleData }));
				if (response != null) {
					return true;
				}
			}
			return false;
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	getSelectedModuleById(selectedModuleGUID: string) {
		if (this.bookData && this.bookData.length > 0) {
			const foundModule = this.bookData.find((x) => x.internalWikiModuleGUID === selectedModuleGUID);
			if (foundModule) {
				if (foundModule.parentInternalWikiModuleGUID) {
					const parentModule = this.findModule(this.sidebarTreeList, foundModule.parentInternalWikiModuleGUID ?? '');
					if (parentModule && parentModule.items) {
						const module = parentModule?.items.find((x) => x.id === selectedModuleGUID);
						return module || null;
					}
				} else {
					const foundModule = this.sidebarTreeList.find((x) => x.id === selectedModuleGUID);
					return foundModule || null;
				}
			}
		}

		return null;
	}

	updateSideBarItem(sideBarItems: WikiManualSidebar[], itemId: string, isActive: boolean) {
		for (const item of sideBarItems) {
			if (item.id === itemId) {
				if (isActive) {
					item.isActive = isActive;
				}
				item.expanded = isActive && item.items.length > 0;
			} else {
				item.expanded = isActive;
			}
		}
		return sideBarItems;
	}

	updateBreadCrumbList() {
		const existingBredCrumbList = [...this.menuService.breadcrumb];
		const breadCrumbList = this.getBookBreadcrumbList();
		if (existingBredCrumbList.length > 0) {
			const updatedList: Breadcrumb[] = existingBredCrumbList.slice(0, 2);

			let resourceCenterData = updatedList.find((x) => x.label == 'Resource Center');
			for (const itm of breadCrumbList) {
				const isExist = updatedList.some((x) => x.label === itm.title);
				if (!isExist) {
					let menuItem: MenuItemModel | undefined;

					const menuItemExist = existingBredCrumbList.find((x) => x.label == itm.title);
					if (!menuItemExist) {
						menuItem = {
							menuGUID: itm.id,
							name: itm.title,
							isRecent: false,
							isFavorite: itm.favoriteYN == 'Y',
							favoriteOrder: itm.favoriteOrder ?? 0,
							parentMenuGUID: resourceCenterData?.url ?? '',
							updateDate: new Date(),
							componentName: resourceCenterData?.label,
							description: itm.title ?? '',
							color: resourceCenterData?.menuItemModel?.color ?? '',
							imageURL: resourceCenterData?.menuItemModel?.imageURL ?? '',
							isNew: false,
							isWikiFavorite: true,
							internalUserXWikiFavoriteGUID: null,
							queryParams: itm.id,
						};
					} else {
						menuItem = menuItemExist.menuItemModel;
					}

					const newBreadcrumb: Breadcrumb = {
						label: itm.title ?? '',
						url: resourceCenterData?.url ?? '',
						queryParams: itm.queryParams ?? { moduleGUID: `${itm.id}` },
						menuItemModel: menuItem,
					};
					updatedList.push(newBreadcrumb);
				}
			}

			this.menuService.modifyBreadCrumb(updatedList);
			return updatedList;
		}

		return null;
	}

	async updateModuleContent(items: WikiManualSidebar[], moduleId: string, newContent: string) {
		for (const item of items) {
			if (item.id === moduleId) {
				// Update the content of the selected module
				item.content = newContent;
				item.moduleHasContent = true;
				break;
			}

			if (item.items && item.items.length > 0) {
				this.updateModuleContent(item.items, moduleId, newContent);
			}
		}
		return items;
	}

	async updateWiKiFavoriteModule(itemId: string, favoriteYN: boolean, favoriteOrder: number) {
		const index = this.bookData.findIndex((x) => x.internalWikiModuleGUID == itemId);
		if (index > -1) {
			this.bookData[index].favoriteYN = favoriteYN ? 'Y' : 'N';
			this.bookData[index].favoriteOrder = favoriteOrder ?? 0;
		}

		await this.updateFavoriteModule(this.sidebarTreeList, itemId, favoriteYN ?? false, favoriteOrder ?? 0);
	}

	async updateFavoriteModule(data: WikiManualSidebar[], itemId: string, favoriteYN: boolean, favoriteOrder: number) {
		function updateItem(item: WikiManualSidebar): boolean {
			if (item.id === itemId) {
				item.favoriteYN = favoriteYN ? 'Y' : 'N';
				item.favoriteOrder = favoriteOrder ?? 0;
				return true;
			}

			if (item.items && item.items.length > 0) {
				for (const subItem of item.items) {
					if (updateItem(subItem)) {
						return true;
					}
				}
			}
			return false;
		}

		data.forEach((item) => updateItem(item));

		this.sidebarTreeList = data;
	}

	getNextBookToDisplay(bookIndex: number) {
		if (this.sidebarTreeList.length > 0) {
			const allBookItems = this.sidebarTreeList.filter((x) => (x.index ?? 0) > bookIndex).sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
			if (allBookItems.length > 0) {
				this.selectedSideBarItem = allBookItems[0];
			} else {
				const defaultItem = this.sidebarTreeList.slice(0, 1);
				if (defaultItem.length > 0) {
					this.selectedSideBarItem = defaultItem[0];
				}
			}
		} else {
			this.selectedSideBarItem = undefined;
		}
		let empty: WikiManualSidebar = {
			items: [],
			index: 0,
			isNewModule: true,
			favoriteYN: 'N',
			isModuleUpdated: true,
			userRole: '',
		};
		this.onSidebarItemSelected.next(this.selectedSideBarItem ?? empty);

		return this.selectedSideBarItem;
	}

	showImportModal(data: WikiImport) {
		this.importModelData.next(data);
	}

	async updateFavoriteItemLabel(wikiFavItems: WikiManualBookResponse[]) {
		wikiFavItems.forEach((item) => {
			this.menuService.menuGroupCollection.forEach((menuGroup: MenuGroupModel) => {
				menuGroup.menuItems.forEach((menuItemModel: MenuItemModel) => {
					if (menuItemModel.menuGUID === item.internalWikiModuleGUID) {
						menuItemModel.name = item.label;
						menuItemModel.description = item.label;
						menuItemModel.isFavorite = item.favoriteYN == 'Y';
						menuItemModel.favoriteOrder = item.favoriteOrder ?? 0;
					}
				});
			});
		});
		const clonedFavoriteMenus = _.cloneDeep(this.menuService.favoriteMenuCollection);
		this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));
	}

	public async getInternalWikiBookLevelData(internalWikiModuleGUID: string): Promise<Result<WikiManualBookResponse[]>> {
		let wikiModuleGuidModel: FetchInternalWikiModuleRequest = {
			internalWikiModuleGUID: internalWikiModuleGUID,
		};
		return await lastValueFrom(this.apiV2.getInternalWikiBookLevelData({ body: wikiModuleGuidModel })).then((response) => {
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<WikiManualBookResponse[]>('Failed to fetch list');
			}
		});
	}

	async importWikiModuleData(bookItems: WikiManualBookResponse[], wikiRoleXPermissionList: WikiRoleXPermissionItem[]) {
		try {
			let permissionData: WikiPermissionRequest = {
				wikiRoleXPermissionList: wikiRoleXPermissionList,
				isSystemAdmin: false,
			};

			let data: UpdateInternalWikiModuleRequest = {
				wikiModuleList: bookItems,
				wikiPermissionList: permissionData,
			};

			let response = await lastValueFrom(this.apiV2.addNewOrUpdateWikiModuleData({ body: data }));
			if (response.isSuccess && response.data && response.data.items) {
				let wikiModuleData: WikiManualBookResponse[] = response.data.items;

				wikiModuleData.forEach((item) => {
					item.depth = item.moduleLevel ?? item.depth;
					item.isNewModule = false;
					item.userRole = item.userRole;
					const index = this.bookData.findIndex((x) => x.internalWikiModuleGUID == item.internalWikiModuleGUID);
					if (index > -1) {
						this.bookData[index] = item;
					} else {
						this.bookData.push(item);
					}
				});
			}

			return response.isSuccess;
		} catch (e) {
			console.log(e, 'Error');
			return false;
		}
	}

	getBookItems(book: WikiManualSidebar): Array<WikiManualBookResponse> {
		const bookItems: Array<WikiManualBookResponse> = [];
		bookItems.push({
			internalWikiModuleGUID: book.id ?? '',
			parentInternalWikiModuleGUID: book.parentId ?? '',
			label: book.text ?? '',
			content: book.content ?? '',
			orderIndex: book.index ?? 0,
			deletedYN: book.deletedYN ?? '',
			depth: book.moduleLevel ?? 0,
			moduleHasContent: book.moduleHasContent ?? false,
			importedData: true,
			favoriteOrder: book.favoriteOrder ?? 0,
			favoriteYN: book.favoriteYN ?? 'N',
			moduleLevel: book.moduleLevel,
			isNewModule: true,
		});
		for (const item of book.items || []) {
			bookItems.push(...this.getBookItems(item));
		}
		return bookItems;
	}

	public async fetchS3VideoStreamPreSignedUrl(fileGUID: string): Promise<Result<S3PresignedUrlResponse>> {
		return await lastValueFrom(this.apiV2.fetchS3ViewPresignedUrl({ fileGUID: fileGUID })).then((response) => {
			if (response.isSuccess && response.data) {
				return ResultHelper.successResponse(response.data ?? '');
			} else {
				return ResultHelper.failedResponse<S3PresignedUrlResponse>('Failed to get presigned video url.');
			}
		});
	}

	public async fetchVideoThumbnailPresignedUrl(fileGUID: string): Promise<Result<S3PresignedUrlResponse>> {
		return await lastValueFrom(this.apiV2.fetchVideoThumbnailPresignedUrl({ fileGUID: fileGUID })).then((response) => {
			if (response.isSuccess && response.data) {
				return ResultHelper.successResponse(response.data ?? '');
			} else {
				return ResultHelper.failedResponse<S3PresignedUrlResponse>('Failed to get thumbnail image');
			}
		});
	}

	public async fetchInternalWikiS3Video(wikiVideoRequest: FetchInternalWikiModuleRequest) {
		if (!this.isVideoApiCallInProgress) {
			this.isVideoApiCallInProgress = true;
			try {
				const response = await lastValueFrom(this.apiV2.getInternalWikiModuleS3Video({ body: wikiVideoRequest }));
				this.isVideoApiCallInProgress = false;

				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<InternalWikiModuleXs3Video[]>('Failed to fetch s3 video list');
				}
			} catch (error) {
				this.isVideoApiCallInProgress = false;
				return ResultHelper.failedResponse<InternalWikiModuleXs3Video[]>('Error fetching s3 video list');
			}
		}
		return null;
	}

	public async getResourceCenterData(request: EntityGetRequest): Promise<Result<WikiManualBookResponse[]>> {
		return await lastValueFrom(this.apiV2.getResourceCenterList({ body: request })).then((response) => {
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<WikiManualBookResponse[]>('Failed to fetch  list');
			}
		});
	}

	public async fetchAllWikiPermissions(menuGUID: string): Promise<Result<InternalPermissionListItem[]>> {
		try {
			let entityGetRequest: EntityGetRequest = {
				searchFilter: [
					{
						searchOption: '=',
						searchColumn: 'InternalMenuGUID',
						searchValue: menuGUID,
						searchColumnType: 'string',
					},
				],
				sortColumns: [{ columnName: 'systemCode', isAscending: true }],
				pageNumber: 1,
				pageSize: 1000,
			};
			return await lastValueFrom(this.apiV2.permissionList({ body: entityGetRequest })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<InternalPermissionListItem[]>('Failed to fetch permission');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<InternalPermissionListItem[]>(error);
		}
	}

	async getWikiRoleData(wkiPermissionList: InternalPermissionListItem[], permissionLabel: string) {
		const matchingPermission = wkiPermissionList.find((permission) => permission.label === permissionLabel);
		if (matchingPermission) {
			const { internalPermissionGUID } = matchingPermission;

			const roleData: WikiRoleXPermissionItem = {
				internalPermissionGUID: internalPermissionGUID,
			};

			return roleData;
		}
		return null;
	}

	async getWikiRolePermissionData(permissionTypeItem: InternalPermissionTypeItem, wikiPermissionData: WikiPermissionModel) {
		if (wikiPermissionData) {
			const modifiedBookName = wikiPermissionData.bookName.replace(/ /g, '_');

			const systemCode = WikiStaticFormatType.PERMISSION_FORMAT.replace('[book_name]', modifiedBookName).replace('[user_role]', permissionTypeItem.userRole ?? '');

			const label = `${wikiPermissionData.bookName} ${permissionTypeItem.userRole ?? ''}`;

			const permissionData: WikiRoleXPermissionItem = {
				internalPermissionGUID: wikiPermissionData.internalPermissionGUID ?? this.newGUID(),
				internalRoleGUID: wikiPermissionData.internalRoleGUID ?? this.newGUID(),
				roleLabel: label,
				roleSystemId: systemCode,
				roleDescription: label,
				permissionSystemCode: systemCode,
				permissionLabel: label,
				permissionDescription: label,
				internalMenuGUID: wikiPermissionData.internalMenuGUID,
				userRole: permissionTypeItem.userRole,
				internalPermissionTypeGUID: permissionTypeItem.internalPermissionTypeGUID,
				accessTypeSystemCode: permissionTypeItem.systemCode,
				internalWikiModuleGUID: wikiPermissionData.internalWikiModuleGUID,
				accessType: permissionTypeItem.label,
				bookName: wikiPermissionData.bookName,
				pageName: wikiPermissionData.pageName,
				isNew: true,
			};

			return permissionData;
		}
		return null;
	}
	public async getSelectedWikiPermissionData(entityGetRequest: EntityGetRequest, isSystemAdmin: boolean) {
		try {
			let wikiRequest: WikiPermissionGetRequest = {
				entityGetRequest: entityGetRequest,
				isSystemAdmin: isSystemAdmin,
			};
			const response = await lastValueFrom(this.apiV2.getWikiRolePermissionData({ body: wikiRequest }));
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<WikiRoleXPermissionItem[]>('Failed to fetch wiki permission list');
			}
		} catch (error) {
			return ResultHelper.failedResponse<WikiRoleXPermissionItem[]>('Error fetching wiki permission list');
		}
	}

	async addNewWikiPermissionData(request: WikiPermissionRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.addNewWikiPermissionData({ body: request })).then((response) => {
			if (response.isSuccess) {
				return ResultHelper.successResponse('Successfully saved role permissions');
			} else {
				return ResultHelper.failedResponse<string>('Failed to save role permissions');
			}
		});
	}
}
