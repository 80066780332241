import { Injectable } from '@angular/core';
import { ApiService } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services/api.service';
import { MenuGroupModel, MenuItemModel, MenuRouteCollection, Result } from '../models/models';
import {
	UpdateFavoriteOrderForUserRequest,
	MenuList,
	InternalUserXMenuRequest,
	MenuGroupRequest,
	DeleteRequest,
	InternalUserWikiFavoriteModel as InternalUserWikiFavoriteModel,
	FetchMenuRequest,
	InternalRecentMenuRequest,
	UpdateFavoriteOrder,
} from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import _ from 'lodash';
import { AuthService } from '../components/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../models/classes';
import { BreadcrumbMarkFavoriteAction, BreadcrumbUpdateAction, MenuGroupUpdateAction, MenuLoadedStateAction } from '../store/store.actions';
import { lastValueFrom } from 'rxjs';
import { ResultHelper } from '../../../../goldstar-share/src/app/common/result-extension';
import { CollectionHelper } from '../../../../goldstar-share/src/app/services/collection.helper';
import { Breadcrumb } from './../utilities/internal-share/models/model';
import { CommonService } from '../../../../goldstar-share/src/app/services/common.service';
import { UserInfoService } from './user-info.service';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	public static menuRouteCollection: MenuRouteCollection;
	// public static menuGroup: MenuGroupModel[] = [];
	public static recentMenu: MenuGroupModel;
	// public static favoriteMenu: MenuGroupModel;
	public menuGroupCollection: MenuGroupModel[] = [];
	public recentMenuCollection: MenuItemModel[] = [];
	public favoriteMenuCollection: MenuItemModel[] = [];
	public breadcrumb: Breadcrumb[] = [];

	private initialized: boolean = false;

	constructor(
		private apiV2: ApiService,
		public authService: AuthService,
		private store: Store<AppState>,
		private commonService: CommonService,
		private userService: UserInfoService,
	) {}

	// This will be called once after a successful user login
	public async initialize() {
		this.store.dispatch(MenuLoadedStateAction({ menuLoaded: false }));
		const menuList = await this.fetchMenu();
		if (menuList.isSuccess) {
			const menuGroupData = _.groupBy(menuList.data, (x) => x.internalMenuGroupGUID);
			this.menuGroupCollection = this.initializeMenuGroupModel(menuGroupData);
			MenuService.menuRouteCollection = this.prepareMenuRouteCollection(CollectionHelper.selectMany<MenuGroupModel, MenuItemModel>(this.menuGroupCollection, (x) => x.menuItems));
			this.initialized = true;
			this.store.dispatch(MenuLoadedStateAction({ menuLoaded: true }));
		}
	}

	/**
	 * Loads all menus from data store
	 * @returns
	 */
	public async fetchMenu(): Promise<Result<MenuList[]>> {
		return await this.commonService.toResultData<MenuList[]>(async () => {
			const fetchMenuRequest: FetchMenuRequest = { userEmail: this.authService.userId };
			const allMenus = await lastValueFrom(this.apiV2.allMenuListRevised({ body: fetchMenuRequest }));
			if (allMenus.isSuccess) {
				const data = allMenus.data?.items;
				return ResultHelper.successResponse<MenuList[]>(data ?? []);
			}
			throw Error('Failed to load data');
		});
	}

	public async loadAllMenus(): Promise<MenuGroupModel[]> {
		return this.menuGroupCollection;
	}

	public async loadAllRecentMenus(): Promise<MenuGroupModel> {
		return MenuService.recentMenu;
	}

	/**
	 * Adds or Update a recent menu item
	 * @param menuItemModel
	 * @param callBacks
	 */
	public addOrUpdateRecentMenu(menuItemModel: MenuItemModel, callBacks: any[] = []) {
		const addOrUpdateMenuItem: InternalRecentMenuRequest = {
			internalMenuGUID: menuItemModel.menuGUID,
			userEmail: this.authService.userId,
			isRecent: true,
		};

		this.addOrUpdateRecentMenuRemote(addOrUpdateMenuItem);
		callBacks.forEach((action: any) => action());
	}

	private async addOrUpdateRecentMenuRemote(request: InternalRecentMenuRequest) {
		await lastValueFrom(this.apiV2.recentAddOrUpdate({ body: request }));
	}

	/**
	 * Adds a favorite menu item to the collection
	 * @param menuItemModel
	 * @param callBacks : Post updates changes
	 * @returns
	 */
	public async addUserFavoriteMenu(menuItemModel: MenuItemModel, callBacks: any[] = []) {
		const allMenuList = CollectionHelper.selectMany(this.menuGroupCollection, (x) => x.menuItems);
		const currentMostFavoriteMenu = CollectionHelper.max<MenuItemModel>(
			allMenuList.filter((x) => x.favoriteOrder != null),
			'favoriteOrder'
		);
		// Update the favorite order of the newly added item
		menuItemModel.favoriteOrder = currentMostFavoriteMenu.favoriteOrder != undefined ? currentMostFavoriteMenu.favoriteOrder + 1 : 0;
		this.menuGroupCollection.forEach((group) => {
			if (menuItemModel.isWikiFavorite) {
				const itemExist = group.menuItems.find((item) => item.menuGUID === menuItemModel.parentMenuGUID);
				if (itemExist) {
					group.menuItems.push(menuItemModel);
				}
			} else {
				const matchingItem = group.menuItems.find((item) => item.menuGUID === menuItemModel.menuGUID);
				if (matchingItem) {
					matchingItem.isFavorite = true;
					matchingItem.favoriteOrder = menuItemModel.favoriteOrder;
				}
			}
		});

		if (menuItemModel.isWikiFavorite) {
			await this.updateUserWikiFavoriteMenu([menuItemModel]);
		} else this.addFavoriteMenuRemote(menuItemModel);
		callBacks.forEach((action: any) => action());
	}

	/**
	 * Updates an existing favorite menu
	 * @param favoriteMenuItems
	 */
	public async updateFavoriteMenuRemote(favoriteMenuItems: MenuItemModel[]) {
		// Prepare the UserFavoriteMenu collection in the database
		let favoriteUpdates: UpdateFavoriteOrder[] = [];
		favoriteMenuItems.forEach((menuItem: MenuItemModel) => {
			favoriteUpdates.push({
				internalMenuGUID: menuItem.menuGUID || '',
				favoriteOrder: menuItem.favoriteOrder || 0,
				favoriteYN: (menuItem.isFavorite ? 'Y' : 'N') || 'N',
			});
		});
		const userGUID: string | undefined = (await this.userService.getCurrentLoggedInUser()).internalUserGUID;
		if(userGUID) {
			const updateFavoriteOrderOfUserMenuRequest: UpdateFavoriteOrderForUserRequest = {
				favoritesOrder: favoriteUpdates,
				internalUserGUID: userGUID,
			};
			await lastValueFrom(this.apiV2.favoriteMenuUpdate({ body: updateFavoriteOrderOfUserMenuRequest }));
		} else {
			console.error('Failed to get user ID for favorites update');
		}
	}

	/**
	 * Adds a favorite menu to the remote data store
	 * @param menuItemModel
	 */
	private async addFavoriteMenuRemote(menuItemModel: MenuItemModel) {
		const request: InternalUserXMenuRequest = {
			userId: this.authService.userId,
			internalMenuGUID: menuItemModel.menuGUID,
			isFavorite: menuItemModel.isFavorite,
			favoriteOrder: menuItemModel.favoriteOrder,
		};
		await lastValueFrom(this.apiV2.favoriteMenuAdd({ body: request }));
	}

	public async updateWikiFavoriteMenu(favoriteMenuItems: MenuItemModel[]) {
		let favoriteUpdates: UpdateFavoriteOrder[] = [];
		favoriteMenuItems.forEach((menuItem: MenuItemModel) => {
			favoriteUpdates.push({
				internalUserXWikiFavoriteGUID: menuItem.internalUserXWikiFavoriteGUID || '',
				favoriteOrder: menuItem.favoriteOrder || 0,
				favoriteYN: (menuItem.isFavorite ? 'Y' : 'N') || 'N',
			});
		});
		const updateFavoriteOrderOfUserMenuRequest: UpdateFavoriteOrderForUserRequest = {
			favoritesOrder: favoriteUpdates,
		};
		console.log(updateFavoriteOrderOfUserMenuRequest);
		await lastValueFrom(this.apiV2.updateWikiFavorite({ body: updateFavoriteOrderOfUserMenuRequest }));
	}

	public async updateUserWikiFavoriteMenu(menuItems: MenuItemModel[]): Promise<boolean> {
		try {
			for (const menuItemModel of menuItems) {
				if (menuItemModel.isWikiFavorite) {
					const request: InternalUserWikiFavoriteModel = {
						userId: this.authService.userId,
						internalWikiModuleGUID: menuItemModel.menuGUID,
						isFavorite: menuItemModel.isFavorite,
						favoriteOrder: menuItemModel.favoriteOrder,
						internalUserXWikiFavoriteGUID: menuItemModel.internalUserXWikiFavoriteGUID ?? '',
					};

					const response = await lastValueFrom(this.apiV2.addOrUpdateWikiFavoriteMenu({ body: request }));

					if (response.isSuccess === undefined) {
						throw new Error('API response has undefined isSuccess property.');
					}

					if (!response.isSuccess) {
						console.error(`Failed to update wiki favorite`);
						return false;
					}
				}
			}

			return true;
		} catch (error) {
			console.error('Error updating wiki favorite menu:', error);
			return false;
		}
	}

	//#region model preparation

	private prepareMenuRouteCollection(menuItems: MenuItemModel[]): MenuRouteCollection {
		let menuRouteCollection: MenuRouteCollection = {};
		menuItems.forEach((menuItem: MenuItemModel) => {
			if (menuItem.menuGUID && menuItem.componentName) {
				menuRouteCollection[menuItem.menuGUID] = menuItem.componentName;
			}
		});
		return menuRouteCollection;
	}

	public initializeMenuGroupModel(menuGroupModel: _.Dictionary<MenuList[]>): MenuGroupModel[] {
		let menuGroupModelList: MenuGroupModel[] = [];
		_.forOwn(menuGroupModel, function (value: MenuList[], key: string) {
			const menuGroupItem: MenuGroupModel = {
				menuGroupGUID: key,
				name: value[0].menuGroup,
				description: value[0].menuGroupDescription,
				isSelected: false,
				menuStyle: 'menuGroup-default-style',
				imageURL: value[0].imageURL,
				color: value[0].color,
				menuItems: value.map((menu: MenuList) => {
					return {
						menuGUID: menu.internalMenuGUID,
						menuID: menu.internalMenuID,
						name: menu.menu,
						updateDate: menu.updateDate ? new Date(menu.updateDate) : new Date('1970-01-01'),
						description: menu.menuDescription,
						imageURL: menu.imageURL,
						color: menu.color,
						modURL: menu.modURL,
						bookmarkedMenuStyle: menu.favoriteYN ? 'active-path-style' : 'path-style',
						menuStyle: 'menu-default-style',
						isRecent: menu.recentYN == null || menu.recentYN == undefined ? false : menu.recentYN,
						isFavorite: menu.favoriteYN == null || menu.favoriteYN == undefined ? false : menu.favoriteYN,
						favoriteOrder: menu.favoriteOrder,
						menuDescriptionStyle: 'menu-description-default-style',
						navigationButtonStyle: menu.favoriteYN == null || menu.favoriteYN == undefined || !menu.favoriteYN ? 'navigation-button-hidden-style' : 'navigation-button-visible-style',
						parentMenuGUID: menu.parentMenuGUID,
						isNew: false,
						componentName: menu.componentName,
						isActive: menu.isActiveYN,
						isExternalLink: menu.isExternalLinkYN,
						externalLinkURL: menu.externalURL,
						isWikiFavorite: menu.isWikiFavorite ?? false,
						queryParams: menu.internalWikiModuleGUID ?? null,
						internalWikiModuleGUID: menu.internalWikiModuleGUID ?? null,
						internalUserXWikiFavoriteGUID: menu.internalUserXWikiFavoriteGUID ?? null,
					};
				}),
				addedMenuItems: [],
				deletedMenuItems: [],
			};
			menuGroupModelList.push(menuGroupItem);
		});
		menuGroupModelList = menuGroupModelList;

		menuGroupModelList = menuGroupModelList.sort((menuGroup1: MenuGroupModel, menuGroup2: MenuGroupModel) => {
			if (!menuGroup1 || !menuGroup1.name || !menuGroup2 || !menuGroup2.name) return 0;

			if (menuGroup1.name >= menuGroup2.name) return 1;

			if (menuGroup1.name < menuGroup2.name) return -1;

			return 0;
		});
		return menuGroupModelList;
	}

	//#end region

	// New layout

	/**
	 * Retrieves a give menu group from datastore
	 * @param internalMenuGroupGUID
	 * @returns
	 */
	public async getSelectedMenu(internalMenuGroupGUID: string): Promise<Result<MenuGroupModel> | Result<string> | Result<any>> {
		if (internalMenuGroupGUID === null) return ResultHelper.failedResponse<string>('MenuGroupGUID cannot be null or empty');

		const allMenu = await this.loadAllMenus();

		const matchingMenuGroup = allMenu.find((x) => x.menuGroupGUID === internalMenuGroupGUID);
		if (!matchingMenuGroup) return ResultHelper.successResponse<undefined>(undefined);
		return ResultHelper.successResponse<MenuGroupModel>(matchingMenuGroup);
	}

	/**
	 * Add, Updates and deletes a menu group and its child menu items
	 */
	public async addOrUpdateMenuGroup(menuGroup: MenuGroupModel): Promise<Result<string>> {
		// First update menu group details in database
		const matchingMenuItem = this.menuGroupCollection.find((x) => x.menuGroupGUID == menuGroup.menuGroupGUID);
		const matchingMenuGroupIndex = this.menuGroupCollection.indexOf(menuGroup);
		const allItems = this.menuGroupCollection.map((x) => JSON.parse(JSON.stringify(x)));

		let addedMenuItems: MenuItemModel[] = [];
		let deletedMenuItems: MenuItemModel[] = [];
		let updatedMenuItems: MenuItemModel[] = [];

		// FOR NEW MENU GROUP
		if (matchingMenuGroupIndex != -1 && matchingMenuItem) {
			// Select all items which are neither part of added or deleted item list
			updatedMenuItems = menuGroup.menuItems.map((x) => x);
			// Prepare list for added menu items
			addedMenuItems = menuGroup.addedMenuItems.map((x) => x);
			// Prepare lits for deleted menu items
			deletedMenuItems = menuGroup.deletedMenuItems.map((x) => x);
			let menuGroupUpdateRequest: MenuGroupRequest = {
				internalMenuGroupGUID: menuGroup.menuGroupGUID,
				description: menuGroup.description,
				label: menuGroup.name,
				iconColor: menuGroup.color,
				iconUrl: menuGroup.imageURL,
				isActive: true,
				newMenuItems: addedMenuItems.map((menuItemModel: MenuItemModel) => {
					return {
						internalMenuGroupGUID: menuGroup.menuGroupGUID,
						internalMenuGUID: menuItemModel.menuGUID,
						description: menuItemModel.description,
						label: menuItemModel.name,
						isExternalLinkYN: menuItemModel.isExternalLink ? 'Y' : 'N',
						parentMenuGUID: menuItemModel.parentMenuGUID,
						componentName: menuItemModel.componentName,
						isActiveYN: menuItemModel.isActive ? 'Y' : 'N',
						externalURL: menuItemModel.externalLinkURL,
					};
				}),
				updatedMenuItems: updatedMenuItems.map((menuItemModel: MenuItemModel) => {
					return {
						internalMenuGroupGUID: menuGroup.menuGroupGUID,
						internalMenuGUID: menuItemModel.menuGUID,
						description: menuItemModel.description,
						label: menuItemModel.name,
						isExternalLinkYN: menuItemModel.isExternalLink ? 'Y' : 'N',
						parentMenuGUID: menuItemModel.parentMenuGUID,
						componentName: menuItemModel.componentName,
						isActiveYN: menuItemModel.isActive ? 'Y' : 'N',
						externalURL: menuItemModel.externalLinkURL,
					};
				}),
				deletedMenuItems: deletedMenuItems.map((menuItemModel: MenuItemModel) => {
					return {
						internalMenuGroupGUID: menuGroup.menuGroupGUID,
						internalMenuGUID: menuItemModel.menuGUID,
						description: menuItemModel.description,
						label: menuItemModel.name,
						isExternalLinkYN: menuItemModel.isExternalLink ? 'Y' : 'N',
						parentMenuGUID: menuItemModel.parentMenuGUID,
						componentName: menuItemModel.componentName,
						isActiveYN: menuItemModel.isActive ? 'Y' : 'N',
						externalURL: menuItemModel.externalLinkURL,
					};
				}),
			};

			const updateMenuResponse = await lastValueFrom(this.apiV2.menuGroupAddOrUpdate({ body: menuGroupUpdateRequest }));
			if (updateMenuResponse.isSuccess) {
				// Update Menu Group
				menuGroup.menuItems = menuGroup.menuItems.concat(menuGroup.addedMenuItems);

				menuGroup.deletedMenuItems.forEach((menuItem: MenuItemModel) => {
					const index = menuGroup.menuItems.findIndex((x) => x.menuGUID === menuItem.menuGUID);
					if (index > -1) {
						menuGroup.menuItems.splice(index, 1);
					}
				});

				this.menuGroupCollection[matchingMenuGroupIndex] = menuGroup;
				MenuService.menuRouteCollection = this.prepareMenuRouteCollection(CollectionHelper.selectMany<MenuGroupModel, MenuItemModel>(this.menuGroupCollection, (x) => x.menuItems));

				this.store.dispatch(MenuGroupUpdateAction({ menuGroups: allItems }));
				return this.ToPromise<Result<string>>(ResultHelper.successResponse<string>('Updated menu group'));
			} else {
				return this.ToPromise<Result<string>>(ResultHelper.failedResponse<string>('Failed to update menu group'));
			}
		} else {
			// Select all items which are neither part of added or deleted item list
			updatedMenuItems = menuGroup.menuItems.map((x) => x);
			// Prepare list for added menu items
			addedMenuItems = menuGroup.addedMenuItems.map((x) => x);
			// Prepare lits for deleted menu items
			deletedMenuItems = menuGroup.deletedMenuItems.map((x) => x);
			let menuGroupUpdateRequest: MenuGroupRequest = {
				internalMenuGroupGUID: menuGroup.menuGroupGUID,
				description: menuGroup.description,
				label: menuGroup.name,
				iconColor: menuGroup.color,
				iconUrl: menuGroup.imageURL,
				isActive: true,
				newMenuItems: addedMenuItems.map((menuItemModel: MenuItemModel) => {
					return {
						internalMenuGroupGUID: menuGroup.menuGroupGUID,
						internalMenuGUID: menuItemModel.menuGUID,
						description: menuItemModel.description,
						label: menuItemModel.name,
						isExternalLinkYN: 'N',
						parentMenuGUID: menuItemModel.parentMenuGUID,
					};
				}),
				updatedMenuItems: updatedMenuItems.map((menuItemModel: MenuItemModel) => {
					return {
						internalMenuGroupGUID: menuGroup.menuGroupGUID,
						internalMenuGUID: menuItemModel.menuGUID,
						description: menuItemModel.description,
						label: menuItemModel.name,
						isExternalLinkYN: 'N',
						parentMenuGUID: menuItemModel.parentMenuGUID,
					};
				}),
				deletedMenuItems: deletedMenuItems.map((menuItemModel: MenuItemModel) => {
					return {
						internalMenuGroupGUID: menuGroup.menuGroupGUID,
						internalMenuGUID: menuItemModel.menuGUID,
						description: menuItemModel.description,
						label: menuItemModel.name,
						isExternalLinkYN: 'N',
						parentMenuGUID: menuItemModel.parentMenuGUID,
					};
				}),
			};

			const updateMenuResponse = await lastValueFrom(this.apiV2.menuGroupAddOrUpdate({ body: menuGroupUpdateRequest }));
			if (updateMenuResponse.isSuccess) {
				// Assigning back the GUID
				menuGroup.menuGroupGUID = updateMenuResponse.data;

				// Update Menu Group
				menuGroup.menuItems = menuGroup.menuItems.concat(menuGroup.addedMenuItems);

				menuGroup.deletedMenuItems.forEach((menuItem: MenuItemModel) => {
					const index = menuGroup.menuItems.findIndex((x) => x.menuGUID === menuItem.menuGUID);
					if (index > -1) {
						menuGroup.menuItems.splice(index, 1);
					}
				});

				this.menuGroupCollection.push(menuGroup);
				MenuService.menuRouteCollection = this.prepareMenuRouteCollection(CollectionHelper.selectMany<MenuGroupModel, MenuItemModel>(this.menuGroupCollection, (x) => x.menuItems));
				this.store.dispatch(MenuGroupUpdateAction({ menuGroups: allItems }));
				return this.ToPromise<Result<string>>(ResultHelper.successResponse<string>('Updated menu group'));
			}
		}
		return this.ToPromise<Result<string>>(ResultHelper.failedResponse<string>('Failed to update menu group'));
	}

	/**
	 * Deletes a menu group and all its related menu items
	 * @param deleteRequest
	 * @returns
	 */
	public async deleteMenuGroup(deleteRequest: DeleteRequest): Promise<Result<string>> {
		try {
			return await lastValueFrom(this.apiV2.menuGroupDelete({ body: deleteRequest }))
				.then((response) => {
					if (response.isSuccess) {
						this.menuGroupCollection = this.menuGroupCollection.filter((x) => x.menuGroupGUID != deleteRequest.guidToDelete);
						this.store.dispatch(MenuGroupUpdateAction({ menuGroups: [] }));
						return ResultHelper.successResponse<string>('Successfully delete menu group');
					} else {
						throw 'Failed to delete menu group';
					}
				})
				.catch((error: any) => {
					throw error;
				});
		} catch (error: any) {
			return ResultHelper.failedResponse<string>('Failed to delete menu group');
		}
	}

	public async buildBreadcrumb(urlGUID: string) {
		this.breadcrumb = [];

		let isFavorite: boolean = false;
		const menuGroup = this.menuGroupCollection.find((x) => x.menuItems.some((y) => y.menuGUID == urlGUID));
		let menuItem = menuGroup?.menuItems.find((x) => x.menuGUID == urlGUID);
		if (menuItem) {
			if (!this.initialized) {
				menuItem.isFavorite = isFavorite;
			}
			this.breadcrumb.push({ label: menuItem.name ?? '', url: menuItem.menuGUID ?? '', menuItemModel: menuItem, queryParams: null });
		}

		while (menuItem?.parentMenuGUID) {
			menuItem = menuGroup?.menuItems.find((x) => x.menuGUID == menuItem?.parentMenuGUID);
			if (menuItem) {
				this.breadcrumb.splice(0, 0, { label: menuItem.name ?? '', url: menuItem.menuGUID ?? '', menuItemModel: menuItem, queryParams: null });
			}
		}

		this.breadcrumb.splice(0, 0, { label: menuGroup?.name ?? '', url: '', menuItemModel: undefined, queryParams: null });
		this.store.dispatch(BreadcrumbUpdateAction({ breadcrumb: _.cloneDeep(this.breadcrumb) }));
	}

	public async markBreadcrumbFavorite(urlGUID: string) {
		this.breadcrumb = [];

		let isFavorite: boolean = false;
		const menuGroup = this.menuGroupCollection.find((x) => x.menuItems.some((y) => y.menuGUID == urlGUID));
		let menuItem = menuGroup?.menuItems.find((x) => x.menuGUID == urlGUID);
		if (menuItem) {
			if (!this.initialized) {
				menuItem.isFavorite = isFavorite;
			}
			this.breadcrumb.push({ label: menuItem.name ?? '', url: menuItem.menuGUID ?? '', menuItemModel: menuItem, queryParams: menuItem.queryParams ?? null });
		}

		while (menuItem?.parentMenuGUID) {
			menuItem = menuGroup?.menuItems.find((x) => x.menuGUID == menuItem?.parentMenuGUID);
			if (menuItem) {
				this.breadcrumb.splice(0, 0, { label: menuItem.name ?? '', url: menuItem.menuGUID ?? '', menuItemModel: menuItem, queryParams: menuItem.queryParams ?? null });
			}
		}

		this.breadcrumb.splice(0, 0, { label: menuGroup?.name ?? '', url: '', menuItemModel: undefined, queryParams: null });
		this.store.dispatch(BreadcrumbMarkFavoriteAction({ breadcrumb: _.cloneDeep(this.breadcrumb) }));
	}

	public modifyBreadCrumb(modifiedBreadcrumb: Breadcrumb[]) {
		this.breadcrumb = modifiedBreadcrumb;
		this.store.dispatch(BreadcrumbUpdateAction({ breadcrumb: this.breadcrumb }));
	}

	/**
	 * Searches Menu and its child Items
	 * @param searchTerm
	 * @returns MenuGroups and it related children
	 */
	public async searchMenuItem(searchTerm: string): Promise<MenuGroupModel[]> {
		const filteredMenuGroups: MenuGroupModel[] = [];

		this.menuGroupCollection.forEach((menuGroup: MenuGroupModel) => {
			if (menuGroup.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) || menuGroup.description?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
				const filteredMenuGroup: MenuGroupModel = {
					menuGroupGUID: menuGroup.menuGroupGUID,
					name: menuGroup.name,
					description: menuGroup.description,
					menuItems: [],
					imageURL: menuGroup.imageURL,
					color: menuGroup.color,
					isSelected: menuGroup.isSelected,
					menuStyle: menuGroup.menuStyle,
					menuGroupID: menuGroup.menuGroupID,
					addedMenuItems: menuGroup.addedMenuItems,
					deletedMenuItems: menuGroup.deletedMenuItems,
				};
				filteredMenuGroups.push(filteredMenuGroup);
			}
			menuGroup.menuItems
				.filter((x) => x.isActive)
				.forEach((menuItem: MenuItemModel) => {
					if (menuItem.name?.toLowerCase().includes(searchTerm.toLowerCase()) || menuItem.description?.toLowerCase().includes(searchTerm.toLowerCase())) {
						let selectedMenuGroup = filteredMenuGroups.find((x) => x.menuGroupGUID === menuGroup.menuGroupGUID);
						if (!selectedMenuGroup) {
							selectedMenuGroup = {
								menuGroupGUID: menuGroup.menuGroupGUID,
								name: menuGroup.name,
								description: menuGroup.description,
								menuItems: [],
								imageURL: menuGroup.imageURL,
								color: menuGroup.color,
								isSelected: menuGroup.isSelected,
								menuStyle: menuGroup.menuStyle,
								menuGroupID: menuGroup.menuGroupID,
								addedMenuItems: menuGroup.addedMenuItems,
								deletedMenuItems: menuGroup.deletedMenuItems,
							};
							filteredMenuGroups.push(selectedMenuGroup);
						}

						const selectedMenuItem: MenuItemModel = {
							name: menuItem.name,
							description: menuItem.description,
							updateDate: menuItem.updateDate,
							bookmarkedMenuStyle: menuItem.bookmarkedMenuStyle,
							color: menuItem.color,
							componentName: menuItem.componentName,
							externalLinkURL: menuItem.externalLinkURL,
							favoriteOrder: menuItem.favoriteOrder,
							imageURL: menuItem.imageURL,
							isActive: menuItem.isActive,
							isExternalLink: menuItem.isExternalLink,
							isFavorite: menuItem.isFavorite,
							isNew: menuItem.isNew,
							isRecent: menuItem.isRecent,
							menuDescriptionStyle: menuItem.menuDescriptionStyle,
							menuGUID: menuItem.menuGUID,
							menuID: menuItem.menuID,
							menuStyle: menuItem.menuStyle,
							modURL: menuItem.modURL,
							navigationButtonStyle: menuItem.navigationButtonStyle,
							parentMenuGUID: menuItem.parentMenuGUID,
						};
						// Add Menu Items
						selectedMenuGroup?.menuItems.push(selectedMenuItem);
					}
				});
		});
		return filteredMenuGroups;
	}

	async ToPromise<T>(val: T) {
		return await new Promise<T>((resolve) => {
			resolve(val);
		}).catch((error) => {
			throw error;
		});
	}
}
