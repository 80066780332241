export enum PageMode {
	EditPageContentComponent = 1,
	AddEditPageContentComponent = 2,
}

export enum WikiStaticUserRolesValues {
	USER = 'User',
	EDITOR = 'Editor',
	MANAGER = 'Manager',
	DEV = 'Dev',
}

export enum WikiRoleAccess {
	VIEW = 'View',
	EDIT = 'Edit',
	ASSIGN = 'assign',
}

export enum InternalPermissionTypeEnum {
	RESOURCE_CENTER_USER = 'ResourceCenter.User',
	RESOURCE_CENTER_EDITOR = 'ResourceCenter.Editor',
	RESOURCE_CENTER_MANAGER = 'ResourceCenter.Manager',
}

export enum WikiStaticFormatType {
	PERMISSION_FORMAT = 'Resource.Center.[book_name]_[user_role]',
}

export interface WikiPermissionModel {
	internalPermissionGUID?: string;
	internalRoleGUID?: string;
	internalMenuGUID: string;
	bookName: string;
	internalWikiModuleGUID: string;
	pageName: string;
}
