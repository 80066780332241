import { Injectable } from '@angular/core';
import { MenuService } from '../../services/menuService';
import { FavoriteMenuGroupItemId, FavoriteMenuGroupItemLabel, FavoriteMenuGroupModel, FavoriteMenuModel } from '../models/models';
import { MenuGroupModel, MenuItemModel } from '../../models/models';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/classes';
import { FavoriteMenuUpdateAction, MenuLoadedStateAction } from '../../store/store.actions';
import { CollectionHelper } from '../../../../../goldstar-share/src/app/services/collection.helper';
import { WikiManualService } from '../components/stand-alone/resource-center/services/wiki-manual.service';

@Injectable({
	providedIn: 'root',
})
export class FavoriteMenuService {
	public favoriteMenuBgColorMap: Record<string, string> = {
		0: '#142B48',
		1: '#2D3081',
		2: '#6BCABA',
		3: '#25669F',
		4: '#86C142',
	};
	constructor(
		private menuService: MenuService,
		private store: Store<AppState>,
		private wikiManualService: WikiManualService
	) {}

	/**
	 * Fetches the user favorite from the remote datastore
	 * @returns
	 */
	public async prepareUserFavoriteMenuGroup(activeTab: string): Promise<FavoriteMenuGroupModel[]> {
		const allMenus = await this.menuService.loadAllMenus();
		if (!allMenus) return [];
		let favoriteMenuGroupModelList: FavoriteMenuGroupModel[] = [
			{
				id: FavoriteMenuGroupItemId.GOLDSTAR,
				label: FavoriteMenuGroupItemLabel.GOLDSTAR,
				isActive: false,
				items: [],
			},
			{
				id: FavoriteMenuGroupItemId.EXTERNAL_LINK,
				label: FavoriteMenuGroupItemLabel.EXTERNAL_LINK,
				isActive: false,
				items: [],
			},
			{
				id: FavoriteMenuGroupItemId.RESOURCE_CENTER,
				label: FavoriteMenuGroupItemLabel.RESOURCE_CENTER,
				isActive: false,
				items: [],
			},
			{
				id: FavoriteMenuGroupItemId.RECENTLY_VISITED,
				label: FavoriteMenuGroupItemLabel.RECENTLY_VISITED,
				isActive: false,
				items: [],
			},
		];
		allMenus.forEach((menuGroup: MenuGroupModel) => {
			menuGroup.menuItems
				.filter((x) => x.isFavorite)
				.forEach((favoriteMenuModel: MenuItemModel) => {
					let model: FavoriteMenuModel = {
						favoriteOrder: favoriteMenuModel.favoriteOrder ?? 0,
						id: favoriteMenuModel.menuGUID ?? '',
						name: favoriteMenuModel.name ?? '',
						iconName: favoriteMenuModel.imageURL ?? '',
						backgroundColor: `#${favoriteMenuModel.color}` ?? '#fff',
						isEditable: false,
						description: favoriteMenuModel.description ?? '',
						menuItemURL: favoriteMenuModel.isExternalLink ? favoriteMenuModel.externalLinkURL ?? '' : favoriteMenuModel.menuGUID ?? '',
						isExternal: favoriteMenuModel.isExternalLink ?? false,
						externalLink: favoriteMenuModel.externalLinkURL ?? '',
						menuItem: favoriteMenuModel,
						queryParams: favoriteMenuModel.queryParams ? { moduleGUID: `${favoriteMenuModel.queryParams}` } : null,
						isWikiFavorite: favoriteMenuModel.isWikiFavorite,
					};
					if (model.isExternal) {
						if (!favoriteMenuGroupModelList[1].items.find((t) => t.id == model.id)) {
							favoriteMenuGroupModelList[1].items.push(model);
						}
					} else if (model.isWikiFavorite) {
						if (!favoriteMenuGroupModelList[2].items.find((t) => t.id == model.id)) {
							favoriteMenuGroupModelList[2].items.push(model);
						}
					} else {
						if (!favoriteMenuGroupModelList[0].items.find((t) => t.id == model.id)) {
							favoriteMenuGroupModelList[0].items.push(model);
						}
					}
				});
		});
		const firstNonEmptyGroup = favoriteMenuGroupModelList.find((group) => group.items.length > 0);
		if (firstNonEmptyGroup) {
			favoriteMenuGroupModelList = favoriteMenuGroupModelList.map(
				(t) => {
					if (t.id == activeTab) {
						return { ...t, isActive: true, items: t.items.sort((a, b) => a.favoriteOrder - b.favoriteOrder) };
					} else return { ...t, isActive: false, items: t.items.sort((a, b) => a.favoriteOrder - b.favoriteOrder) };
				}
				// ({
				// 	...t,
				// 	isActive: t.id === firstNonEmptyGroup.id,
				// 	items: [...t.items].sort((a, b) => a.favoriteOrder - b.favoriteOrder),
				// })
			);
		}
		return favoriteMenuGroupModelList;
	}
	/**
	 * Fetches the user favorite from the remote datastore
	 * @returns
	 */
	public async prepareUserFavoriteMenu(): Promise<FavoriteMenuModel[]> {
		let favoriteGroupList = await this.prepareUserFavoriteMenuGroup(FavoriteMenuGroupItemId.GOLDSTAR);
		return favoriteGroupList.reduce<FavoriteMenuModel[]>((acc, group) => acc.concat(group.items), []);
	}
	/**
	 * Updates favorite menu order
	 * @param updatedFavoriteModels
	 */
	public async saveFavoriteMenu(updatedFavoriteModels: FavoriteMenuModel[]): Promise<void> {
		// Update the Menu Collection for the Side Bar Menu to update
		this.menuService.menuGroupCollection.forEach((menuGroup: MenuGroupModel) => {
			menuGroup.menuItems.forEach((menuItem: MenuItemModel, index: number) => {
				if (menuItem) {
					let matchingUpdatedFavoriteMenuItem = updatedFavoriteModels.find((x) => x.id === menuItem.menuGUID);
					let menuItemToUpdate = { ...menuItem, isFavorite: true, favoriteOrder: menuItem.favoriteOrder };
					if (matchingUpdatedFavoriteMenuItem) {
						menuItemToUpdate.isFavorite = matchingUpdatedFavoriteMenuItem != null;
						menuItemToUpdate.favoriteOrder = matchingUpdatedFavoriteMenuItem.favoriteOrder || menuItem.favoriteOrder;
					} else {
						menuItemToUpdate.isFavorite = false;
					}
					menuGroup.menuItems[index] = menuItemToUpdate;
				}
			});
		});
		const allMenuList = CollectionHelper.selectMany(this.menuService.menuGroupCollection, (x) => x.menuItems);

		//Handled for wiki and non - wiki favorite items
		let wikiFavoriteItems = allMenuList.filter((x) => x.isWikiFavorite);
		let nonWikiFavoriteItems = allMenuList.filter((x) => !x.isWikiFavorite);
		if (wikiFavoriteItems.length > 0) {
			wikiFavoriteItems.forEach(async (x) => {
				await this.wikiManualService.updateWiKiFavoriteModule(x.menuGUID ?? '', x.isFavorite ?? false, x.favoriteOrder ?? 0);
			});
			await this.menuService.updateWikiFavoriteMenu(wikiFavoriteItems);
		}

		if (nonWikiFavoriteItems.length > 0) await this.menuService.updateFavoriteMenuRemote(nonWikiFavoriteItems);

		const clonedFavoriteMenus = _.cloneDeep(this.menuService.favoriteMenuCollection);
		this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));
		this.store.dispatch(MenuLoadedStateAction({ menuLoaded: false }));
		this.store.dispatch(MenuLoadedStateAction({ menuLoaded: true }));
	}
}
