import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToDoService } from '../../../../../../../../modules/services/todo.service';
import { FormsModule, NgForm } from '@angular/forms';
import { StandAloneBaseLayoutComponent } from '../../../../../../../../modules/components/stand-alone/base/stand-alone-base-layout/stand-alone-base-layout.component';
import { TodoListEventStatus } from '../enum';
import { InternalToDoEventModel, InternalToDoStatusModel, ToDoEventSnoozeHistory } from '../../../../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ErrorHandlingService } from '../../../../../../../../../../../goldstar-share/src/app/services/error-handling.service';
import moment from 'moment';
import { UserInfoService } from '../../../../../../../../services/user-info.service';
import { DxNumberBoxModule, DxTextAreaModule } from 'devextreme-angular';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToDoActionModelData } from '../to-do-list.models';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, StandAloneBaseLayoutComponent, FormsModule, DxTextAreaModule, DxNumberBoxModule],
	selector: 'app-to-do-list-action-model',
	templateUrl: './to-do-list-action-model.component.html',
	styleUrls: ['./to-do-list-action-model.component.scss'],
})
export class ToDoListActionModelComponent extends StandAloneBaseLayoutComponent {
	comments: string = '';
	modalAction: string = '';
	showToDoActionModal: boolean = false;
	selectedToDoItem!: InternalToDoEventModel;
	toDoStatusList: InternalToDoStatusModel[] = [];
	snoozeDays: number | null = null;
	maxSnoozeLimit: number = 50;
	loggedInInternalUserGUID: string = '';
	limitExceeded: boolean = false;
	newSnoozeDate!: string;
	disableEventButton: boolean = false;

	@ViewChild('textField') textField!: NgForm;

	constructor(
		private toDoService: ToDoService,
		private errorHandlingService: ErrorHandlingService,
		private userInfoServices: UserInfoService,
		public modalRef: MatDialogRef<ToDoActionModelData>,
		@Inject(MAT_DIALOG_DATA) public data: ToDoActionModelData
	) {
		super();
	}

	async ngOnInit() {
		let apiRequests: any[] = [this.toDoService.fetchAllInternalToDoStatusData(), this.userInfoServices.getCurrentLoggedInUser()];
		await Promise.all(apiRequests)
			.then((response) => {
				if (response && response.length >= 2) {
					this.toDoStatusList = response[0].data ?? [];
					this.loggedInInternalUserGUID = response[1].internalUserGUID ?? '';
				} else {
					throw 'failed to fetch data.';
				}
			})
			.catch((error) => {
				this.errorHandlingService.showErrorMessage('failed to fetch data.');
				this.modalRef.close();
			});

		// const response = await this.toDoService.fetchAllInternalToDoStatusData();
		// if (response.data) {
		// 	this.toDoStatusList = response.data;
		// }
		// let currentUser = await this.userInfoServices.getCurrentLoggedInUser();
		// this.loggedInInternalUserGUID = currentUser.internalUserGUID ?? '';
		if (this.data) {
			this.showToDoActionModal = this.data.showToDoActionModal;
			this.modalAction = this.data.modalAction;
			this.selectedToDoItem = this.data.todoItem;
			if (this.selectedToDoItem && this.selectedToDoItem.maxSnoozedLength) {
				this.maxSnoozeLimit = this.selectedToDoItem.maxSnoozedLength;
				if (this.selectedToDoItem.lastSnoozedLength && this.selectedToDoItem.snoozeHistoryDate) {
					let format = 'YYYY-MM-DD';
					const snoozeDate = this.selectedToDoItem.snoozeHistoryDate;
					let days = moment(this.selectedToDoItem.maxSnoozeDate, format).diff(moment(snoozeDate, format), 'days');
					this.maxSnoozeLimit = days;
				}
			}
		}
	}

	override resolveComponentName(): string | undefined {
		return 'ToDoListActionModelComponent';
	}

	closeActionModal() {
		this.modalAction = '';
		this.comments = '';
		this.snoozeDays = null;
		this.newSnoozeDate = '';
		this.showToDoActionModal = false;
		this.disableEventButton = false;
		this.limitExceeded = false;
		this.modalRef.close();
	}

	maxValueValidator() {
		const maxSnoozeLimit = this.maxSnoozeLimit;
		const value = this.snoozeDays;
		this.limitExceeded = false;
		if (value && value > maxSnoozeLimit) {
			this.limitExceeded = true;
		}
		this.disableEventButton = this.limitExceeded;

		return this.limitExceeded;
	}

	onNgModelChange(ngModel: NgForm) {
		const { form } = ngModel;

		if (form.controls?.snoozeDays?.value && form.controls?.snoozeDays?.value > 0) {
			this.getSnoozeDate(form.controls?.snoozeDays?.value);

			this.maxValueValidator();
		}
	}

	getSnoozeDate(snoozeLength: number) {
		if (this.selectedToDoItem) {
			let dueDate = null;

			if (this.selectedToDoItem.snoozeHistoryDate) {
				dueDate = moment(this.selectedToDoItem.snoozeHistoryDate);
			} else {
				dueDate = moment(this.selectedToDoItem.dueDate);
			}
			dueDate.add(snoozeLength, 'days');
			this.newSnoozeDate = dueDate.format();
		}
	}

	async saveToDoSnoozeHistoryData() {
		if (this.snoozeDays && this.snoozeDays > 0) {
			if (this.selectedToDoItem) {
				this.disableEventButton = true;

				this.selectedToDoItem.modifiedByUserGUID = this.loggedInInternalUserGUID;

				let snoozeHistoryData: ToDoEventSnoozeHistory = {
					internalToDoEventGUID: this.selectedToDoItem.internalToDoEventGUID ?? '',
					snoozeLength: this.snoozeDays,
					modifiedByUserGUID: this.loggedInInternalUserGUID,
				};

				snoozeHistoryData.snoozeUntilDate = this.newSnoozeDate;
				this.selectedToDoItem.snoozeHistoryDate = this.newSnoozeDate;
				this.selectedToDoItem.lastSnoozedLength = this.snoozeDays;

				const response = await this.toDoService.addTodoSnoozeHistory(snoozeHistoryData);

				if (response) {
					this.toDoService.updatedToDoItem.next(this.selectedToDoItem);

					this.errorHandlingService.showSuccessMessage('Data Saved Successfully', false);
				} else {
					this.errorHandlingService.showErrorMessage('Error Saving Data', false);
				}

				this.closeActionModal();
			}
		} else {
			this.textField.controls.snoozeDays.markAsTouched();
		}
	}

	async saveToDoListEventActionData(modalAction: string) {
		if (this.comments && this.comments != '') {
			if (this.selectedToDoItem) {
				this.disableEventButton = true;

				const statusList = this.toDoStatusList;
				let internalToDoStatusGUID = '';
				const targetStatus = modalAction === 'complete' ? TodoListEventStatus.COMPLETED : TodoListEventStatus.CANCELLED;
				const matchingStatus = statusList.find((x) => x.systemCode?.trim().toLowerCase() === targetStatus.trim().toLowerCase());

				if (matchingStatus) {
					internalToDoStatusGUID = matchingStatus.internalToDoStatusGUID ?? '';
					this.selectedToDoItem.eventStatus = matchingStatus.label ?? '';
				}

				this.selectedToDoItem.internalToDoStatusGUID = internalToDoStatusGUID;
				this.selectedToDoItem.comment = this.comments;

				this.selectedToDoItem.modifiedByUserGUID = this.loggedInInternalUserGUID;

				const response = await this.toDoService.updateToDoActionData(this.selectedToDoItem);

				if (response) {
					this.toDoService.updatedToDoItem.next(this.selectedToDoItem);

					this.errorHandlingService.showSuccessMessage('Data Saved Successfully', false);
				} else {
					this.errorHandlingService.showErrorMessage('Error Saving Data', false);
				}

				this.closeActionModal();
			}
		}
		{
			this.textField.controls.comments.markAsTouched();
		}
	}
}
