import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[appTopNavbarHeaderImage]',
})
export class TopNavbarHeaderImageDirective implements AfterViewInit, OnChanges {
	@Input('headerImage') headerImage: boolean = false;

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.headerImage) {
			this.addHeaderImage();
		} else {
			this.removeHeaderImage();
		}
	}

	ngAfterViewInit(): void {
		if (this.headerImage) {
			this.addHeaderImage();
		} else {
			this.removeHeaderImage();
		}
	}

	/**
	 * Add the header image to the body container
	 */
	addHeaderImage() {
		const htmlElement = this.elementRef.nativeElement as HTMLElement;
		const rootElement = htmlElement.closest('.main-container');
		if (rootElement) {
			// Set the height of each section containers
			const bodyContainer = rootElement.querySelector('.body-container');
			this.renderer.addClass(bodyContainer, 'with-header');
			this.renderer.removeClass(bodyContainer, 'without-header');
		}
	}

	/**
	 * Removes the header image from the body container
	 */
	removeHeaderImage() {
		const htmlElement = this.elementRef.nativeElement as HTMLElement;
		const rootElement = htmlElement.closest('.main-container');
		if (rootElement) {
			// Set the height of each section containers
			const bodyContainer = rootElement.querySelector('.body-container');
			this.renderer.addClass(bodyContainer, 'without-header');
			this.renderer.removeClass(bodyContainer, 'with-header');
		}
	}
}
