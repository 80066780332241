import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ComponentHeightInPixel } from '../../../../../models/classes';
import { ApplyMarginOnBodyAction, DirtySaveHasUnsavedChangeAction, NavbarTitleUpdatedAction } from '../../../../../store/store.actions';
import { applyMarginOnBody } from '../../../../../store/store.selector';
import { TopNavbarHeaderImageDirective } from '../../../../directives/top-navbar-header-image.directive';
import { CommonModule } from '@angular/common';
import { INamedComponent } from '../../../../models/models';
import { SpinnerState } from '../../../../../models/models';
import { LoaderService } from '../../../loader/service/loader.service';
import { EditorStyle, LabelMode } from 'devextreme/common';

@Component({
	standalone: true,
	imports: [TopNavbarHeaderImageDirective, CommonModule],
	selector: 'app-stand-alone-base-layout',
	templateUrl: './stand-alone-base-layout.component.html',
	styleUrls: ['./stand-alone-base-layout.component.scss'],
})
export class StandAloneBaseLayoutComponent implements OnInit, AfterViewInit, OnChanges, INamedComponent, OnDestroy {
	public styleCollection: Record<string, string | undefined | null> = {
		height: '76%',
	};
	public readonly layoutComponentFixedHeightInPixel =
		ComponentHeightInPixel.breadcrumbHeight + ComponentHeightInPixel.footerHeight + ComponentHeightInPixel.topNavbarHeight + ComponentHeightInPixel.paddingHeight;
	protected showSpinner!: boolean;
	public stylingMode: EditorStyle = 'outlined';
	public labelMode: LabelMode = 'hidden';

	@Input() headerImage: boolean = false;
	layoutWithMargin: boolean = true;

	constructor(public elRef?: ElementRef, public store?: Store<AppState>, public loaderService?: LoaderService) {
		this.store?.dispatch(ApplyMarginOnBodyAction({ applyMargin: true }));

		this.store?.select(applyMarginOnBody).subscribe((applyMargin: boolean) => {
			this.layoutWithMargin = applyMargin;
		});

		// The base header title updates for all screens
		this.store?.dispatch(NavbarTitleUpdatedAction({ title: '' }));
	}
	ngOnDestroy(): void {}

	formHeaderTitle!: string;
	ngOnInit(): void {}
	ngOnChanges(changes: SimpleChanges): void {
		this.headerImage = this.headerImage;
	}

	ngAfterViewInit(): void {}
	resolveComponentName(): string | undefined {
		throw 'Not implemented exception';
	}

	async calculateScreenHeight() {
		const screenHeight = window.innerHeight;
		if (screenHeight) {
			const contentContainerHeightInPixel = screenHeight - this.layoutComponentFixedHeightInPixel;
			this.styleCollection = {
				height: `${contentContainerHeightInPixel}px !important`,
			};
		}
	}

	/* <summary>
	description: Needs to be triggered when form value has been changed 
	<summary>*/
	formHasUnSavedChanges(hasChanges: boolean) {
		this.store?.dispatch(DirtySaveHasUnsavedChangeAction({ hasUnSavedChanges: hasChanges }));
	}

	//app loader
	toggleSpinner(showLoader: boolean) {
		let state = showLoader ? SpinnerState.Show : SpinnerState.Hide;
		switch (state) {
			case SpinnerState.Show:
				this.showSpinner = true;
				this.loaderService?.showLoader();
				break;
			case SpinnerState.Hide:
			default:
				this.showSpinner = false;
				this.loaderService?.hideLoader();
				break;
		}
	}
}
