import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, firstValueFrom, lastValueFrom } from 'rxjs';
import { AuthService } from '../components/auth/auth.service';
import {
	EntityGetRequest,
	InternalUser,
	InternalUserItem,
	RoleForUserList,
	RolesForUserRequest,
	RolesForUserSaveList,
	LanguagePreference,
	CustomerAppointmentService,
	EmployeeProfileRequest,
	FetchUserDetails,
	EmployeeBirthdayRequest,
	EmployeeAnniversaryRequest,
	EmployeeAnniversaryItem,
	InternalUserProfileRequest,
	S3FileDownloadResponse,
} from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ApiService } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { ResultHelper } from '../../../../goldstar-share/src/app/common/result-extension';
import { EmployeeAnniversaryItemResponse, EmployeeBirthdayItemResponse, Result } from '../models/models';
import { Store } from '@ngrx/store';
import { AppState } from '../models/classes';
import { userIdState } from '../store/store.selector';
import { FileModel } from '../../../../goldstar-share/src/app/models/models';

import { S3FileService } from '../../../../goldstar-share/src/app/services/s3file-service';

@Injectable({
	providedIn: 'root',
})
export class UserInfoService implements OnDestroy {
	private readonly defaultItem: InternalUserItem[] = [];
	public allUsers: BehaviorSubject<InternalUserItem[]> = new BehaviorSubject(this.defaultItem);
	public allActiveUsers: InternalUserItem[] = [];

	public currentLoggedInUser: BehaviorSubject<any> = new BehaviorSubject({});
	private users: InternalUserItem[] = [];

	public languagePreferenceList: LanguagePreference[] = [];
	public customerAppointmentServiceList: CustomerAppointmentService[] = [];
	public employeeProfileDetails!: EmployeeProfileRequest;

	// Subscription
	private userLoadedObserver$!: Subscription;

	constructor(
		private authService: AuthService,
		private apiV2: ApiService,
		private store: Store<AppState>,
		private fileService: S3FileService
	) {
		// SUBSCRIBING TO USER LOADED EVENT
		this.userLoadedObserver$ = this.store.select(userIdState).subscribe(async (userId) => {
			if (userId) {
				this.loadRemoteLoggedInUser(userId);
			}
		});
	}
	ngOnDestroy(): void {
		this.userLoadedObserver$.unsubscribe();
	}

	async loadRemoteLoggedInUser(userId: string) {
		if (this.authService.userId) {
			const fetchAllUsers: EntityGetRequest = {
				searchFilter: [
					{
						searchOption: '=',
						searchColumn: 'Email',
						searchValue: userId,
					},
				],
				pageSize: 1000,
			};
			const fetchAllUsersResponse = await firstValueFrom(this.apiV2.userList({ body: fetchAllUsers }));
			if (fetchAllUsersResponse.isSuccess && fetchAllUsersResponse.data && fetchAllUsersResponse.data.items) {
				this.users = fetchAllUsersResponse.data?.items;
				const currentLoggedInUser = fetchAllUsersResponse.data.items.find((x) => x.userId == userId);
				if (currentLoggedInUser) this.currentLoggedInUser.next(currentLoggedInUser);
				this.allUsers.next(fetchAllUsersResponse.data.items);
			}
		}
	}

	async initialize(userId: string) {
		if (this.allUsers.getValue().length <= 1) {
			if (this.authService.userId) {
				const fetchAllUsers: EntityGetRequest = {
					searchFilter: [{ searchOption: 'ALL' }],
					pageSize: 1000,
				};
				const fetchAllUsersResponse = await firstValueFrom(this.apiV2.userList({ body: fetchAllUsers }));
				if (fetchAllUsersResponse.isSuccess && fetchAllUsersResponse.data && fetchAllUsersResponse.data.items) {
					this.users = fetchAllUsersResponse.data?.items;
					const currentLoggedInUser = fetchAllUsersResponse.data.items.find((x) => x.userId == userId);
					if (currentLoggedInUser) this.currentLoggedInUser.next(currentLoggedInUser);
					this.allUsers.next(fetchAllUsersResponse.data.items);
				}
			}
		}
	}

	/**
	 * fetches all users
	 * @returns
	 */
	async fetchAllUsers(): Promise<InternalUserItem[]> {
		if (this.allUsers.getValue().length <= 1) {
			await this.initialize(this.authService.userId);
			return this.allUsers.getValue();
		}
		return this.allUsers.getValue();
	}

	/**
	 * Fetches all permissions from data store (This is not user specific, but fetches all permissions)
	 * @returns
	 */
	public async fetchAllUsersData(): Promise<Result<InternalUserItem[]>> {
		try {
			let entityGetRequest: EntityGetRequest = {
				searchFilter: [{ searchOption: 'ALL' }],
				// sortColumns: [{ columnName: 'systemCode', isAscending: true }],
				// pageNumber: 1,
				// pageSize: 1000,
			};
			return await lastValueFrom(this.apiV2.userList({ body: entityGetRequest })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<InternalUserItem[]>('Failed to fetch roles');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<InternalUserItem[]>(error);
		}
	}

	/**
	 * Fetch matching user from data store
	 * @param internalUserGUID
	 * @returns
	 */
	async fetchUser(internalUserGUID: string): Promise<Result<InternalUserItem[]>> {
		try {
			if (this.allUsers.getValue().length <= 1) {
				await this.initialize(this.authService.userId);
			}
			const users = this.allUsers.getValue().filter((x) => x.internalUserGUID === internalUserGUID);
			return ResultHelper.successResponse<InternalUserItem[]>(users);
		} catch (error: any) {
			return ResultHelper.failedResponse<InternalUserItem[]>(error);
		}
	}

	async fetchUserByEmail(email: string): Promise<Result<InternalUserItem>> {
		try {
			const request: EntityGetRequest = {
				searchFilter: [
					{
						searchColumnType: 'string',
						searchOption: '=',
						searchColumn: 'email',
						searchValue: email,
					},
				],
			};
			const userResponse = await lastValueFrom(this.apiV2.userList({ body: request }));
			if (userResponse.isSuccess && userResponse.data?.items && userResponse.data.items.length > 0) {
				return ResultHelper.successResponse<InternalUserItem>(userResponse.data.items[0]);
			}

			return ResultHelper.failedResponse<InternalUserItem>('User is Missing');
		} catch (error: any) {
			return ResultHelper.failedResponse<InternalUserItem>(error);
		}
	}

	/**
	 * Adds a user
	 * @param userItem
	 */
	async addUser(userItem: InternalUserItem): Promise<Result<string>> {
		try {
			if (!userItem) throw 'User  to be saved cannot be blank ro empty';
			return await this.addRemoteUser(userItem)
				.then((response) => {
					if (!response.isSuccess) throw 'Failed to save user';
					userItem.internalUserGUID = response.data;
					const allUserItems = this.allUsers.getValue();
					allUserItems.push(userItem);
					this.allUsers.next(allUserItems);
					return ResultHelper.successResponse<string>(response.data ?? '');
				})
				.catch((error: any) => {
					throw 'Failed to save user';
				});
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	/**
	 * Saves an user in database
	 * @param userItem
	 */
	private async addRemoteUser(userItem: InternalUserItem): Promise<Result<string>> {
		try {
			const userRequest: InternalUser = {
				name: userItem.name,
				email: userItem.email,
				primaryNotificationMode: userItem.primaryNotificationMode,
				internalTitleGUID: userItem.titleGUID,
				managerInternalUserGUID: userItem.managerInternalUserGUID,
				activeYN: userItem.activeYN,
			};
			return await lastValueFrom(this.apiV2.userUpdate({ body: userRequest }))
				.then((response) => {
					if (!response.isSuccess) throw 'Failed to save user';
					const saveUser = response.data;
					return ResultHelper.successResponse(saveUser);
				})
				.catch((error: any) => {
					throw error;
				});
		} catch (error: any) {
			return ResultHelper.failedResponse<string>(error);
		}
	}

	/**
	 * Updates an user
	 * @param userItem
	 */
	async updateUser(userItem: InternalUserItem): Promise<Result<string>> {
		try {
			if (!userItem) throw 'User to be saved cannot be blank or empty';
			return await this.updateRemoteUser(userItem)
				.then((response) => {
					if (!response.isSuccess) throw 'Failed to save user';
					const allUserItems = this.allUsers.getValue().filter((x) => x.internalUserGUID != userItem.internalUserGUID);
					allUserItems.push(userItem);
					this.allUsers.next(allUserItems);
					return ResultHelper.successResponse('Successfully saved user');
				})
				.catch((error: any) => {
					throw error;
				});
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	/**
	 * Saves an user in remote database
	 * @param userItem
	 */
	private async updateRemoteUser(userItem: InternalUserItem): Promise<Result<string>> {
		try {
			const userRequest: InternalUser = {
				internalUserGUID: userItem.internalUserGUID,
				name: userItem.name,
				email: userItem.email,
				primaryNotificationMode: userItem.primaryNotificationMode,
				internalTitleGUID: userItem.titleGUID,
				managerInternalUserGUID: userItem.managerInternalUserGUID,
				activeYN: userItem.activeYN,
			};
			return await lastValueFrom(this.apiV2.userUpdate({ body: userRequest }))
				.then((response) => {
					if (!response.isSuccess) throw 'Failed to save user';
					const saveUser = response.data;
					return ResultHelper.successResponse(saveUser);
				})
				.catch((error: any) => {
					throw error;
				});
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	/**
	 * fetches the current logged in user
	 * @returns
	 */
	async getCurrentLoggedInUser(): Promise<InternalUserItem> {
		if (!this.currentLoggedInUser.getValue()) {
			await this.initialize(this.authService.userId);
			return this.currentLoggedInUser.getValue();
		}
		const currentLoggedInUser = this.users.find((x) => x.userId == this.authService.userId);
		if (currentLoggedInUser) {
			this.currentLoggedInUser.next(currentLoggedInUser);
			return currentLoggedInUser;
		}
		return this.currentLoggedInUser.getValue();
	}

	/**
	 * fetches the present user line
	 * @param selectedUserGUID
	 * @returns
	 */
	async getUserBusinessLine(selectedUserGUID: string): Promise<InternalUserItem[]> {
		const matchingUser = (await this.fetchAllUsers()).find((x) => x.internalUserGUID === selectedUserGUID);
		let managerHierarchyList: InternalUserItem[] = [];
		if (matchingUser) {
			managerHierarchyList = await this.getManager(matchingUser, managerHierarchyList);
		}
		return managerHierarchyList.filter((x) => x.internalUserGUID != selectedUserGUID);
	}

	/*
		Fetches all roles assigned to a given user 
	*/
	async fetchRolesForUser(request: RolesForUserRequest): Promise<Result<RoleForUserList[]>> {
		return await lastValueFrom(this.apiV2.roleForUserList({ body: request }))
			.then((response) => {
				if (!response.isSuccess) return ResultHelper.failedResponse<RoleForUserList[]>('Failed to load data');
				if (response.data?.items) return ResultHelper.successResponse<RoleForUserList[]>(response.data?.items);
				return ResultHelper.successResponse([]);
			})
			.catch((error) => {
				return ResultHelper.failedResponse<RoleForUserList[]>(error);
			});
	}

	/**
	 * Adds roles to user
	 * @param request
	 * @returns
	 */
	public async addRolesToUser(request: RolesForUserSaveList): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.userRolesAdd({ body: request }))
			.then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse('Successfully saved roles for user');
				} else {
					return ResultHelper.successResponse('Failed to save roles for users');
				}
			})
			.catch((error: any) => {
				return ResultHelper.failedResponse('Failed to save roles for users');
			});
	}

	/**
	 * Deletes roles from user
	 * @param request
	 */
	public async deleteRolesForUser(request: RolesForUserSaveList): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.userRolesDelete({ body: request }))
			.then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse('Successfully delete roles for user');
				} else {
					return ResultHelper.successResponse('Failed to delete roles for users');
				}
			})
			.catch((error) => {
				return ResultHelper.failedResponse('Failed to delete roles for users');
			});
	}

	private async getManager(selectedUser: InternalUserItem, userItemList: InternalUserItem[]): Promise<InternalUserItem[]> {
		if (selectedUser) {
			if (selectedUser.managerInternalUserGUID) {
				const matchingUser = (await this.fetchAllUsers()).find((x) => x.internalUserGUID === selectedUser.managerInternalUserGUID);
				if (matchingUser) {
					userItemList = await this.getManager(matchingUser, userItemList);
				}
			}
			userItemList.push(selectedUser);
			return userItemList;
		}
		return userItemList;
	}

	async fetchAllLanguagePreference(): Promise<LanguagePreference[]> {
		if (this.languagePreferenceList && this.languagePreferenceList.length == 0) {
			await lastValueFrom(this.apiV2.languagePreferenceList()).then(async (response) => {
				if (response.isSuccess) {
					const languagePreferenceList = response.data?.items;
					if (languagePreferenceList) {
						this.languagePreferenceList = languagePreferenceList;
					}
				}
			});
		}
		return this.languagePreferenceList;
	}
	async fetchAllEmployeeProfileDetails(internalUserGUID: string): Promise<EmployeeProfileRequest> {
		let userDetails: FetchUserDetails = {
			internalUserGUID: internalUserGUID,
		};

		await lastValueFrom(this.apiV2.getEmployeeDetails({ body: userDetails })).then(async (response) => {
			if (response.isSuccess) {
				const employeeDetails = response.data?.items;
				if (employeeDetails) {
					this.employeeProfileDetails = employeeDetails[0];
				}
			}
		});

		return this.employeeProfileDetails;
	}

	async fetchAllCustomerAppointmentService(): Promise<CustomerAppointmentService[]> {
		if (this.customerAppointmentServiceList && this.customerAppointmentServiceList.length == 0) {
			await lastValueFrom(this.apiV2.customerAppointmentServiceList()).then(async (response) => {
				if (response.isSuccess) {
					const customerAppointmentData = response.data?.items;
					if (customerAppointmentData) {
						this.customerAppointmentServiceList = customerAppointmentData;
					}
				}
			});
		}
		return this.customerAppointmentServiceList;
	}

	public async fetchEmployeeBirthdayList(pageNumber: number, pageSize: number): Promise<Result<EmployeeBirthdayItemResponse>> {
		try {
			let loggedInUser = await this.getCurrentLoggedInUser();
			let request: EmployeeBirthdayRequest = {
				loggedInUserGUID: loggedInUser.internalUserGUID ?? '',
				managerGUID: loggedInUser.managerInternalUserGUID ?? '',
				pageNumber: pageNumber,
				pageSize: pageSize,
			};
			return await lastValueFrom(this.apiV2.fetchEmployeeBirthdayList({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse<EmployeeBirthdayItemResponse>({
						birthdayList: response.data?.items ?? [],
						totalRowCount: response.data?.totalRowCount ?? 0,
					});
				} else {
					return ResultHelper.failedResponse<EmployeeBirthdayItemResponse>('Failed to fetch data');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<EmployeeBirthdayItemResponse>(error);
		}
	}

	async fetchAllActiveUsers(): Promise<InternalUserItem[]> {
		if (this.allUsers.getValue().length == 0) {
			await this.initialize(this.authService.userId);
		}
		if (this.allActiveUsers.length == 0) {
			const fetchAllUsersResponse = await firstValueFrom(this.apiV2.fetchActiveUserList());
			if (fetchAllUsersResponse.isSuccess && fetchAllUsersResponse.data && fetchAllUsersResponse.data.items) {
				this.allActiveUsers = fetchAllUsersResponse.data?.items;
			}
			return this.allActiveUsers;
		}
		return this.allActiveUsers;
	}
	public async fetchEmployeeAnniversaryList(pageNumber: number, pageSize: number): Promise<Result<EmployeeAnniversaryItemResponse>> {
		try {
			let loggedInUser = await this.getCurrentLoggedInUser();
			let request: EmployeeAnniversaryRequest = {
				loggedInUserGUID: loggedInUser.internalUserGUID ?? '',
				managerGUID: loggedInUser.managerInternalUserGUID ?? '',
				pageNumber: pageNumber,
				pageSize: pageSize,
			};
			return await lastValueFrom(this.apiV2.fetchEmployeeAnniversaryList({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse<EmployeeAnniversaryItemResponse>({
						anniversaryList: response.data?.items ?? [],
						totalRowCount: response.data?.totalRowCount ?? 0,
					});
				} else {
					return ResultHelper.failedResponse<EmployeeAnniversaryItemResponse>('Failed to fetch data');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<EmployeeAnniversaryItemResponse>(error);
		}
	}

	async uploadEmployeeProfileFile(internalUserGUID: string, fileModels: FileModel[]): Promise<Result<any>> {
		let s3FileGUID: string = '';
		let smallPictureS3FileGUID: string = '';
		for (const fileModel of fileModels) {
			try {
				const response = await this.fileService.uploadProfileImage(fileModel.file.name, fileModel.file);

				if (response) {
					if (!fileModel.isFileResized) {
						s3FileGUID = response;
					} else {
						smallPictureS3FileGUID = response;
					}
				} else {
					throw `Failed to upload file: ${fileModel.file.name}`;
				}
			} catch (error) {
				return ResultHelper.failedResponse(`Failed to upload s3 files`);
			}
		}

		if (s3FileGUID != '' && smallPictureS3FileGUID != '') {
			try {
				const employeeProfileRequest: InternalUserProfileRequest = {
					internalUserGUID: internalUserGUID,
					s3FileGUID: s3FileGUID ?? '',
					smallPictureS3FileGUID: smallPictureS3FileGUID,
				};

				const saveResult = await this.saveEmployeeProfileImage(employeeProfileRequest);

				if (saveResult.isSuccess) {
					return ResultHelper.successResponse('Successfully saved files with fileGUID');
				} else {
					throw 'File upload failed';
				}
			} catch (error) {
				console.error(error);
				return ResultHelper.failedResponse(`Failed to save profile image`);
			}
		} else {
			throw 'File GUID is required';
		}
	}

	async saveEmployeeProfileImage(request: InternalUserProfileRequest): Promise<Result<any>> {
		const response = await lastValueFrom(this.apiV2.addEmployeeProfileImage({ body: request }));
		if (response.isSuccess) {
			const currentUsers = this.allUsers.getValue();
			const userIndex = currentUsers.findIndex((user) => user.internalUserGUID === request.internalUserGUID);
			if (userIndex !== -1) {
				// Update the user's name
				currentUsers[userIndex].profileImageS3File = response.data;

				// Emit the new array to the BehaviorSubject
				this.allUsers.next([...currentUsers]);
			}
			return ResultHelper.successResponse('Successfully saved files with fileGUID ---');
		} else {
			return ResultHelper.failedResponse('Failed to update file storage');
		}
	}

	public async updateUserBirthdayVisibility(request: EmployeeBirthdayRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.updateUserBirthdayVisibility({ body: request }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}
}
