import {
	UserGroupFetchRequest,
	UserGroupUpdateRequest,
	UserGroupItem,
	UserGroupAddRequest,
	UserPerGroupGridModel,
	InternalUserItem,
	DeleteRequest,
} from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { BehaviorSubject } from 'rxjs';
import { UserGroupService } from './user-group.service';
import { ResultHelper } from '../../../../../goldstar-share/src/app/common/result-extension';
import { Result } from '../../../../../goldstar-share/src/app/models/models';
import { Injectable } from '@angular/core';
import { CollectionHelper } from '../../../../../goldstar-share/src/app/services/collection.helper';
import { UserInfoService } from '../user-info.service';

@Injectable({
	providedIn: 'root',
})
export class UserGroupDataStore {
	private readonly defaultUserGroup: UserGroupAddRequest = {};
	public userGroupModelList: BehaviorSubject<UserGroupAddRequest[]> = new BehaviorSubject([this.defaultUserGroup]);

	constructor(
		private userGroupService: UserGroupService,
		private userInfoService: UserInfoService
	) {}

	/**
	 * Fetches all user groups from database
	 * @param request
	 * @returns
	 */
	async getValues(request: UserGroupFetchRequest): Promise<Result<UserGroupAddRequest[]>> {
		try {
			let allValues = this.userGroupModelList.getValue();
			if (!allValues || allValues.length <= 1) {
				const firstValue = allValues[0];
				if (firstValue == this.defaultUserGroup) {
					let allGroups = await this.fetch(request);
					allGroups = CollectionHelper.sort<UserGroupAddRequest>(allGroups, 'name');
					allValues = allGroups;
					this.userGroupModelList.next(allGroups);
					if (!request.groupName && (!request.userGroupGUIDs || request.userGroupGUIDs.length <= 0)) {
						return ResultHelper.successResponse(allGroups);
					}
				}
			}
			let filteredValues = this.applyFilter(request, allValues);
			filteredValues = CollectionHelper.sort<UserGroupAddRequest>(filteredValues, 'name');
			return ResultHelper.successResponse(filteredValues);
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	/**
	 * Adds a new user group
	 * @param userGroup
	 * @returns
	 */
	async push(userGroup: UserGroupAddRequest): Promise<Result<string>> {
		return await this.userGroupService
			.addUserGroup(userGroup)
			.then((response) => {
				if (response.isSuccess && response.data) {
					userGroup.internalUserGroupGUID = response.data;
					this.addValue(userGroup);
					return response;
				}
				throw 'Error Fetching record';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	/**
	 * Updates an existing user group
	 * @param userGroupUpdateRequest
	 * @returns
	 */
	async update(userGroupUpdateRequest: UserGroupUpdateRequest): Promise<Result<string>> {
		return await this.userGroupService
			.updateUserGroup(userGroupUpdateRequest)
			.then(async (response) => {
				if (response.isSuccess && response.data) {
					const matchingUserGroupToUpdate = this.userGroupModelList.getValue().find((x) => x.internalUserGroupGUID === userGroupUpdateRequest.guidToUpdate);
					if (matchingUserGroupToUpdate) {
						matchingUserGroupToUpdate.name = userGroupUpdateRequest.name;
						matchingUserGroupToUpdate.description = userGroupUpdateRequest.description;
						matchingUserGroupToUpdate.systemCode = userGroupUpdateRequest.systemCode;

						// REMOVE ALL ITEMS WHICH HAVE BEEN DELETED
						matchingUserGroupToUpdate.notificationModelPerUserGroup = matchingUserGroupToUpdate.notificationModelPerUserGroup?.filter((value: UserPerGroupGridModel) => {
							const findMatchingInDeleteList = userGroupUpdateRequest.removedUserList?.find((x) => x.internalUserGUID === value.internalUserGUID);
							if (!findMatchingInDeleteList) return value;
							return undefined;
						});

						// ADD THE NEW ITEMS WHICH HAVE BEEN ADDED
						matchingUserGroupToUpdate.notificationModelPerUserGroup = CollectionHelper.filterUndefinedValues<UserPerGroupGridModel>(
							matchingUserGroupToUpdate.notificationModelPerUserGroup ?? []
						).concat(userGroupUpdateRequest.addedUserList ?? []);
					}
					return response;
				}
				throw Error('Failed to save user group');
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	async delete(userGroupDeleteRequest: DeleteRequest): Promise<Result<string>> {
		return this.userGroupService
			.deleteUserGroup(userGroupDeleteRequest)
			.then((response: Result<string>) => {
				if (response.isSuccess) {
					const allUserGroups = this.userGroupModelList.getValue().filter((x) => x.internalUserGroupGUID != userGroupDeleteRequest.guidToDelete);
					this.userGroupModelList.next(allUserGroups);
					return ResultHelper.successResponse('Successfully delete user group');
				}
				throw 'Failed to delete user group';
			})
			.catch((error: any) => {
				return ResultHelper.failedResponse(error);
			});
	}

	private async fetch(userGroupFetchRequest: UserGroupFetchRequest): Promise<UserGroupAddRequest[]> {
		return await this.userGroupService
			.userGroupList(userGroupFetchRequest)
			.then(async (response) => {
				if (response.isSuccess && response.data) {
					return response.data.map((x: UserGroupItem) => {
						let userGroupAddRequest: UserGroupAddRequest = {
							name: x.name,
							description: x.description,
							systemCode: x.systemCode,
							internalUserGroupGUID: x.internalUserGroupGUID,
							notificationModelPerUserGroup: x.userList?.map((user: InternalUserItem) => {
								let userGroupPerGridModel: UserPerGroupGridModel = {
									name: user.name,
									email: user.email,
									allowSendingNotification: true,
									isNew: false,
									internalUserGUID: user.internalUserGUID,
								};
								return userGroupPerGridModel;
							}),
						};
						return userGroupAddRequest;
					});
				}
				throw Error('Error Fetching record');
			})
			.catch((error) => {
				throw error;
			});
	}

	private applyFilter(request: UserGroupFetchRequest, values: UserGroupAddRequest[]): UserGroupAddRequest[] {
		let filteredValues = values;
		if (request.groupName) {
			filteredValues = values.filter((x) => x.name?.includes(request.groupName ?? ''));
		}
		const userGroupGUIDList = CollectionHelper.filterUndefinedValues<string>(request.userGroupGUIDs ?? []);
		if (userGroupGUIDList.length > 0) {
			filteredValues = values.filter((x) => x.internalUserGroupGUID?.includes(userGroupGUIDList[0]));
		}
		return filteredValues;
	}

	private addValue(userGroup: UserGroupAddRequest) {
		let allGroups = this.userGroupModelList.getValue();
		allGroups.push(userGroup);
		allGroups = CollectionHelper.sort<UserGroupAddRequest>(allGroups, 'name');
		return this.userGroupModelList.next(allGroups);
	}
}
